/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useRef } from 'react';

import Link from 'next/link';
import StyledButton from '@/components/Button';
import {
  OrderIcon,
  PreviouslyOrderedIcon,
  ProfileIcon,
} from '@/assets/icons/account';
import {
  UnselectedAlerzoIcon,
  SKProfileIcon,
} from '@/components/BottomNavigationBar/icons';
import EntityImage from '@/components/EntityImage';
import DesktopDialog from '@/components/DesktopDialog';
import Menu from '@/components/Menu';
import LoaderDialog from '@/components/LoaderDialog';

// Styles
import classnames from 'classnames';
import { UserIcon, DropdownIcon } from '@/assets/icons/desktop';

// Utils
import { decodeURIString } from '@/utils/helper';
import { AuthUserContext } from '@/contexts/authUser';
import { GqlContext } from '@/contexts/gql';
import { logout } from '@/services/auth';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';

const DesktopSignin = () => {
  const anchorRef = useRef(null);
  const { t: translate } = useTranslation();
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const toggleVisibility = () => setLocationDropdownOpen((prevOpen) => !prevOpen);
  const { authUser = {} } = useContext(AuthUserContext);
  const { site } = useContext(GqlContext);
  const {
    businessName,
    customerIdentifier,
    Stores: [store] = [],
    phoneNo,
  } = authUser;
  const { city, typeOfStore } = store || {};
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true);
    logout(authUser?.customerId).catch(console.error);
  };

  const isLoggedIn = !!authUser.customerId;

  const profileClasses = classnames('row j-start a-center', styles.profile);

  return (
    <div
      className={styles.container}
      ref={anchorRef}
      tabIndex={0}
      onKeyDown={() => setLocationDropdownOpen(true)}
      role="button"
      onClick={() => setLocationDropdownOpen(true)}
    >
      {isLoggedIn ? (
        <div className={styles.dropdownButton}>
          {site?.isVeedez ? (
            <p>My Account </p>
          ) : (
            <p>
              <span className={styles.businessName}>{businessName}</span>
              <br />
              My Account
            </p>
          )}

          <DropdownIcon
            className={
              locationDropdownOpen
                ? styles.dropdownIconReversed
                : styles.dropdownIcon
            }
          />
        </div>
      ) : (
        <div className={styles.dropdownButton} id="signInDropdownButton">
          <p>{translate('Sign In')}</p>

          <UserIcon className={styles.userIcon} />

          <DropdownIcon
            className={
              locationDropdownOpen
                ? styles.dropdownIconReversed
                : styles.dropdownIcon
            }
          />
        </div>
      )}

      {/* Dropdown */}
      <DesktopDialog
        open={locationDropdownOpen}
        setOpen={toggleVisibility}
        anchorRef={anchorRef}
        className={styles.dropdownContainer}
      >
        <div
          className={profileClasses}
          style={loading ? { position: 'relative' } : {}}
        >
          <LoaderDialog loading={loading} />
          {businessName ? (
            <div>
              <EntityImage
                className={styles.avatar}
                entity={{ name: businessName }}
              />
            </div>
          ) : (
            <div className={styles.avatar}>
              {site?.isSK ? (
                <SKProfileIcon />
              ) : site?.isAlerzo ? (
                <UnselectedAlerzoIcon className={styles.alerzoLogo} />
              ) : (
                <UnselectedAlerzoIcon className={styles.alerzoLogo} />
              )}
            </div>
          )}
          {isLoggedIn ? (
            <>
              <p className={styles.name}>{businessName || phoneNo}</p>
              <p className={styles.info}>
                {`ID: ${customerIdentifier} | ${typeOfStore} | ${decodeURIString(
                  city,
                )}`}
              </p>
            </>
          ) : (
            <>
              <Link href="/login">
                <StyledButton className={styles.actionButton} id="signInButton">
                  {translate('Sign in')}
                </StyledButton>
              </Link>
              <div className={styles.register}>
                {translate('New customer?')}
                <Link href="/login">
                  <span className={styles.registerLink} id="register">
                    {translate('Register here')}
                  </span>
                </Link>
              </div>
            </>
          )}

          {isLoggedIn && (
            <>
              <ul className={styles.menuGroup}>
                <Menu
                  className={styles.menuItem}
                  label={translate('My Orders')}
                  icon={<OrderIcon />}
                  href="/orders"
                />
                <Menu
                  className={styles.menuItem}
                  label={translate('Previously Ordered Items')}
                  icon={<PreviouslyOrderedIcon />}
                  href="/orders/previousOrders"
                />
                <Menu
                  className={styles.menuItem}
                  label={translate('My Profile')}
                  icon={<ProfileIcon />}
                  href="/account/edit"
                />
              </ul>
              {!site?.isVeedez ? (
                <div className={styles.buttonContainer}>
                  <button
                    type="button"
                    className={styles.button}
                    onClick={handleLogout}
                  >
                    {translate('Log Out')}
                  </button>
                </div>
              ) : null}
            </>
          )}
        </div>
      </DesktopDialog>
    </div>
  );
};

export default DesktopSignin;
