// Core Packages
import React, {
  useState, useMemo, useRef, useContext, useEffect,
} from 'react';
import classnames from 'classnames';
import Link from 'next/link';

// Custom Components
import { CartIcon } from '@/assets/icons/desktop';
import DesktopDialog from '@/components/DesktopDialog';
import StyledButton from '@/components/Button';
import SummaryTable from '@/components/CartSummaryTable';
import CartItem from '@/components/CartItem';
import BundlePopup from '@/containers/BundlePopup';

// Material
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Assets
import { DesktopCartIcon } from '@/assets/icons/shared';

// Utils
import { getFilteredProductsByAvailability } from '@/utils/helper';
import { AuthUserContext } from '@/contexts/authUser';
import { CartContext } from '@/contexts/cart';
import { useTranslation } from 'react-i18next';

// styles
import styles from './style.module.css';

const DesktopCart = ({
  getCartItems,
  updateProductQuantity,
  removeItemFromCart,
  getTotalPriceOfCart,
  getGrossTotalOfCart,
  getItemQuantityForBundle,
  showMiniNotification,
  isLoggedIn,
}) => {
  const isDesktop = useMediaQuery('(min-width: 769px)');
  const { t: translate } = useTranslation();
  const { authUser } = useContext(AuthUserContext);
  const { getSellerDetails } = useContext(CartContext);
  // eslint-disable-next-line no-unused-vars
  const [showBundlePopup, setShowBundlePopup] = useState('');
  const [showDesktopPopup, setShowDesktopPopup] = useState(false);
  const [address, setAddress] = useState('');
  const cartItems = isLoggedIn ? getCartItems() : [];
  const [availableCartItems, unAvailableCartItems] = getFilteredProductsByAvailability(cartItems);

  const anchorRef = useRef(null);
  const totalPrice = useMemo(() => getTotalPriceOfCart(), [cartItems]);

  const grossTotal = useMemo(() => getGrossTotalOfCart(), [cartItems]);

  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const toggleVisibility = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    setLocationDropdownOpen((prevOpen) => !prevOpen);

  const handleShowBundle = (bundleReceived) => {
    setShowBundlePopup(bundleReceived._id);
    setShowDesktopPopup(isDesktop);
  };

  const buttonClasses = classnames('row j-center a-center', styles.button, {
    [styles.disabled]: cartItems.length > 0,
  });
  const removeItem = (unAvailableItems) => {
    unAvailableItems.forEach((product) => {
      const sellerDetails = getSellerDetails(product._id);
      updateProductQuantity(
        product,
        0,
        product.sellerId || sellerDetails?.sellerId,
        product.sellerName || sellerDetails?.sellerName,
      );
    });
  };
  useEffect(() => {
    if (!isLoggedIn) return;
    if (authUser?.agentName) setAddress(authUser?.Stores?.[0]?.address);
  }, [authUser]);

  return (
    <>
      {showBundlePopup && (
        <BundlePopup
          setOpen={setShowBundlePopup}
          updateProductQuantity={updateProductQuantity}
          getItemQuantityForBundle={getItemQuantityForBundle}
          bundleId={showBundlePopup}
          cartItems={cartItems}
          showMiniNotification={showMiniNotification}
          showDesktopPopup={showDesktopPopup}
        />
      )}
      <div className={styles.container}>
        {isLoggedIn ? (
          <div
            className={styles.dropdownButton}
            ref={anchorRef}
            tabIndex={0}
            onKeyDown={() => setLocationDropdownOpen(true)}
            role="button"
            onClick={() => setLocationDropdownOpen(true)}
          >
            <p className={styles.dropdownText}>{translate('Cart')}</p>
            {cartItems?.length ? (
              <DesktopCartIcon
                className={styles.icon}
                quantity={cartItems.length}
              />
            ) : (
              <CartIcon />
            )}
          </div>
        ) : (
          <Link href="/login">
            <div className={styles.dropdownButton}>
              <p className={styles.dropdownText}>Cart</p>
              <CartIcon />
            </div>
          </Link>
        )}
        {isLoggedIn && (
          <DesktopDialog
            open={locationDropdownOpen}
            setOpen={toggleVisibility}
            anchorRef={anchorRef}
            className={styles.dropdownContainer}
          >
            <div className={styles.cartContainer}>
              {cartItems?.length ? (
                <>
                  <h4 className={styles.summaryTitle}>
                    {translate('Cart')}
                    {' '}
                    <span style={{ color: '#69778C' }}>
                      (
                      {availableCartItems?.length}
                      {' '}
                      {translate(
                        availableCartItems?.length > 1 ? 'items' : 'item',
                      )}
                      )
                    </span>
                  </h4>
                  {availableCartItems.length > 0 ? (
                    <div className={styles.cartItemContainer}>
                      {availableCartItems.map((it) => (
                        <CartItem
                          key={`${it._id}_${it.sellerId}`}
                          product={it}
                          isEditing
                          updateProductQuantity={updateProductQuantity}
                          removeItemFromCart={removeItemFromCart}
                          getItemQuantityForBundle={getItemQuantityForBundle}
                          cartItems={cartItems}
                          showBundle={handleShowBundle}
                          showMiniNotification={showMiniNotification}
                          sellerDetails={getSellerDetails(it._id)}
                        />
                      ))}
                    </div>
                  ) : null}
                  {unAvailableCartItems?.length > 0 ? (
                    <div className={styles.unAvailableItems}>
                      <p>
                        {`${unAvailableCartItems.length} unavailable item(s) in your cart.`}
                      </p>

                      <button
                        className={styles.itemDelete}
                        type="button"
                        onClick={() => removeItem(unAvailableCartItems)}
                      >
                        {translate('Remove')}
                      </button>
                    </div>
                  ) : null}

                  <SummaryTable
                    discount={(grossTotal || totalPrice) - totalPrice}
                    totalPrice={totalPrice}
                    shippingAddress={address}
                    isMiniCart
                  />

                  <Link href="/cart">
                    <StyledButton className={styles.actionButton}>
                      {translate('View Cart')}
                    </StyledButton>
                  </Link>
                </>
              ) : (
                <div className={styles.placeholderContainer}>
                  <img
                    src="/images/emptyCart.png"
                    alt="Empty Cart"
                    className={styles.image}
                  />
                  <p className={styles.caption}>
                    {translate('Your cart is empty')}
                  </p>
                  <div
                    className={buttonClasses}
                    tabIndex={0}
                    onKeyDown={toggleVisibility}
                    role="button"
                    onClick={toggleVisibility}
                  >
                    <Link href="/">{translate('Start Shopping')}</Link>
                  </div>
                </div>
              )}
            </div>
          </DesktopDialog>
        )}
      </div>
    </>
  );
};

export default DesktopCart;
