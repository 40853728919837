// Core Packages
import {
  useState, useEffect, useContext, useMemo,
} from 'react';
import classnames from 'classnames';

// MaterialUI
import { Drawer } from '@material-ui/core';

// Custom Components
import BundleProduct from '@/components/BundleProduct';
import BundleV2Product from '@/components/BundleV2Product';

// Utils
import { useTranslation } from 'react-i18next';
import { AuthUserContext } from '@/contexts/authUser';
import { CartContext } from '@/contexts/cart';
import { BundleContext } from '@/contexts/bundle';
import LoaderDialog from '@/components/LoaderDialog';
import BackButton from '@/components/BackButtonNew';
import StyledCarousel from '@/components/Carousel';
import { SelectedAlerzoIcon } from '@/components/BottomNavigationBar/icons';
import { formatAmount } from '@/utils/helper';
import { GqlContext } from '@/contexts/gql';

// Styles
import styles from './style.module.css';

const BundlePopup = ({
  setOpen,
  updateProductQuantity,
  bundleId,
  getItemQuantityForBundle,
  cartItems,
  showMiniNotification,
  showDesktopPopup,
}) => {
  const { t: translate } = useTranslation();
  const { authUser } = useContext(AuthUserContext);
  const { country } = useContext(GqlContext);
  const { getItemQuantityBySeller } = useContext(CartContext);
  const {
    bundles,
    fetchBundleDetails,
    bundleLoading,
    getTotalPriceOfBundle,
    getGrossTotalOfBundle,
  } = useContext(BundleContext);

  // discounts including bundle savings and wholesale price
  const totalPrice = useMemo(
    () => getTotalPriceOfBundle(bundleId),
    [cartItems],
  );

  // pure retail prices
  const grossTotal = useMemo(
    () => getGrossTotalOfBundle(bundleId),
    [cartItems],
  );

  const [bundleSeller, setBundleSeller] = useState(null);

  const loading = bundleLoading[bundleId] || false;

  const [bundleName, setBundleName] = useState('');
  const [bundleImages, setBundleImages] = useState([]);
  const [bundleDescription, setBundleDescription] = useState('');
  const [bundleType, setBundleType] = useState('');
  const [bundleProducts, setBundleProducts] = useState([]);
  const [discountActive, setDiscountActive] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [minimumQuantity, setMinimumQuantity] = useState(5);
  const handleProductQuantity = (product, quantity, sellerId, sellerName) => {
    updateProductQuantity(product, quantity, sellerId, sellerName);
  };
  const [showNav, setShowNav] = useState(!!bundleId);

  const handleClose = () => {
    setShowNav(false);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const refreshPrices = (bundleSellerId) => {
    if (!bundles[bundleId]) return;

    const { activateWholesale, bundleCount } = getItemQuantityForBundle(
      bundleId,
      bundleSellerId || bundleSeller,
    );
    setDiscountActive(activateWholesale);
    setCartCount(bundleCount);
  };

  useEffect(() => {
    if (!bundles[bundleId]) {
      fetchBundleDetails(bundleId);
      return;
    }

    const bundle = bundles[bundleId];

    const userCity = authUser?.Stores[0]?.cityId;

    const bundleCity = bundle?.bundleCities?.find(
      (ct) => ct.bundleCityId === userCity,
    );

    const bundleForAll = bundle?.bundleCities?.find(
      (ct) => ct.bundleCityId === '*',
    );

    // Bundle Name
    const nameFromBundleDetail = bundle?.bundleName;
    const nameFromData = bundle.bundleProducts?.[0]?.bundle?.bundleName;
    const newBundleName = nameFromBundleDetail || nameFromData;

    // Bundle info
    const { images, description } = bundle.bundleInfo;

    let imagesArray = images.map((image) => ({
      image,
    }));

    imagesArray = imagesArray.filter(
      (imageObject) => imageObject.image.trim().length > 0,
    );

    console.log(bundleDescription);

    const bundleType = bundle?.bundleType;

    // Bundle Min Quantity
    const minQtyFromBundleDetail = bundleCity?.minimumQuantity || bundleForAll?.minimumQuantity;
    const minQtyFromData = bundle.bundleProducts?.[0]?.bundle?.minimumQuantity;
    const newMinQty = minQtyFromBundleDetail || minQtyFromData;
    const bundleSellerId = bundle?.sellerId || null;

    // add check for hide from alerzo off
    const bundleProductsRefined = bundle.bundleProducts.filter(
      (product) => !product?.hideFromAlerzo,
    );
    setBundleName(newBundleName);
    setBundleImages(imagesArray);
    setBundleDescription(description);
    setBundleType(bundleType);
    setBundleProducts(bundleProductsRefined);
    setMinimumQuantity(newMinQty);
    setBundleSeller(bundleSellerId);
    refreshPrices(bundleSellerId);
  }, [bundles, authUser]);

  useEffect(() => {
    refreshPrices();
  }, [cartItems]);

  const smallScreenClasses = classnames(styles.smallScreenOnly);

  const bundlePopupText = useMemo(() => {
    if (bundleType === 'DISCOUNT') {
      if (cartCount - minimumQuantity < 0) {
        return translate(
          'Add {qty} quantity of bundle items to unlock best offer.',
        ).replace('{qty}', minimumQuantity - cartCount);
      }

      return 'Exciting offers have been unlocked for you in this bundle.';
    }

    if (cartCount - minimumQuantity < 0) {
      return translate(
        'Add {qty} quantity of any item in the bundle to get a wholesale price on all items in the bundle.',
      ).replace('{qty}', minimumQuantity - cartCount);
    }

    return 'Wholesale prices have been activated for all items in this bundle';
  }, [bundleType, cartCount, minimumQuantity]);

  return (
    <>
      {showDesktopPopup ? (
        <div className={styles.largeScreenOnly}>
          <Drawer
            variant="temporary"
            anchor="right"
            open={showNav}
            onClose={handleClose}
            transitionDuration={{ appear: 400, enter: 400, exit: 400 }}
            classes={{ paper: styles.paperDesktop }}
          >
            {loading ? (
              <LoaderDialog loading />
            ) : (
              <>
                <BackButton
                  className={styles.backContainer}
                  onClick={handleClose}
                  showCross
                />
                <StyledCarousel
                  images={bundleImages}
                  src="bundle"
                  className={styles.bundleCarousel}
                />

                <div className={styles.dialogTitleBox}>
                  <div className={styles.dialogTitleRow}>
                    <span className={styles.heading}>{bundleName}</span>
                    <span className={styles.quanityInCart}>
                      {`${cartCount} of ${minimumQuantity}`}
                    </span>
                  </div>
                  <p className={styles.description}>{bundlePopupText}</p>
                  <div className={styles.brandingContainer}>
                    <SelectedAlerzoIcon className={styles.alerzoIcon} />
                    <span className={styles.quanityInCart}>
                      Sold by Alerzoshop
                    </span>
                  </div>
                </div>
                <div
                  style={discountActive ? { paddingBottom: '3.5rem' } : {}}
                  className={styles.dialogItemsList}
                >
                  {bundleProducts?.length ? (
                    <>
                      {bundleType === 'DISCOUNT' && (
                        <div className={styles.bundleType}>Promoted Items</div>
                      )}
                      {bundleType === 'DISCOUNT'
                        && bundleProducts
                          .filter((b) => b?.bundle?.productType === 'PROMOTION')
                          .map((product) => (
                            <BundleV2Product
                              key={product._id}
                              product={product}
                              bundleSeller={bundleSeller}
                              onQuantityChange={handleProductQuantity}
                              cartQuantity={getItemQuantityBySeller(
                                bundleSeller,
                                product._id,
                              )}
                              bundleId={bundleId}
                              source={bundleName}
                            />
                          ))}
                      {bundleType === 'DISCOUNT' && (
                        <div className={styles.bundleType}>Bundle Items</div>
                      )}
                      {bundleProducts
                        .filter((b) => b?.bundle?.productType === 'BUNDLE')
                        .map((product) => (
                          <BundleProduct
                            key={product._id}
                            product={product}
                            bundleActivator={minimumQuantity - cartCount}
                            cartQuantity={getItemQuantityBySeller(
                              bundleSeller,
                              product._id,
                            )}
                            onQuantityChange={handleProductQuantity}
                            discountActive={discountActive}
                            showMiniNotification={showMiniNotification}
                            bundleSeller={bundleSeller}
                            source={bundleName}
                          />
                        ))}
                    </>
                  ) : null}
                </div>

                {discountActive && (
                  <div className={styles.bundleStatus}>
                    <del>
                      {`${country?.currencySymbol} ${formatAmount(
                        grossTotal,
                        true,
                      )}`}
                    </del>
                    <ins>
                      {`${country?.currencySymbol} ${formatAmount(
                        totalPrice,
                        true,
                      )}`}
                    </ins>
                  </div>
                )}
              </>
            )}
          </Drawer>
        </div>
      ) : (
        <div className={smallScreenClasses}>
          <Drawer
            variant="temporary"
            anchor="left"
            open={showNav}
            onClose={handleClose}
            transitionDuration={{ appear: 400, enter: 400, exit: 400 }}
            classes={{ paper: styles.paper }}
          >
            {loading ? (
              <LoaderDialog loading />
            ) : (
              <>
                <BackButton
                  className={styles.backContainer}
                  onClick={handleClose}
                />
                <StyledCarousel images={bundleImages} src="bundle" />

                <div className={styles.dialogTitleBox}>
                  <div className={styles.dialogTitleRow}>
                    <span className={styles.heading}>{bundleName}</span>
                    <span className={styles.quanityInCart}>
                      {`${cartCount} of ${minimumQuantity}`}
                    </span>
                  </div>
                  <p className={styles.description}>{bundlePopupText}</p>
                  <div className={styles.brandingContainer}>
                    <SelectedAlerzoIcon className={styles.alerzoIcon} />
                    <span className={styles.quanityInCart}>
                      Sold by Alerzoshop
                    </span>
                  </div>
                </div>
                <div
                  style={discountActive ? { paddingBottom: '3.5rem' } : {}}
                  className={styles.dialogItemsList}
                >
                  {bundleProducts?.length ? (
                    <>
                      {bundleType === 'DISCOUNT' && (
                        <div className={styles.bundleType}>Promoted Items</div>
                      )}
                      {bundleType === 'DISCOUNT'
                        && bundleProducts
                          .filter((b) => b?.bundle?.productType === 'PROMOTION')
                          .map((product) => (
                            <BundleV2Product
                              key={product._id}
                              product={product}
                              bundleSeller={bundleSeller}
                              onQuantityChange={handleProductQuantity}
                              cartQuantity={getItemQuantityBySeller(
                                bundleSeller,
                                product._id,
                              )}
                              bundleId={bundleId}
                              source={bundleName}
                            />
                          ))}
                      {bundleType === 'DISCOUNT' && (
                        <div className={styles.bundleType}>Bundle Items</div>
                      )}
                      {bundleProducts
                        .filter((b) => b?.bundle?.productType === 'BUNDLE')
                        .map((product) => (
                          <BundleProduct
                            key={product._id}
                            product={product}
                            bundleActivator={minimumQuantity - cartCount}
                            cartQuantity={getItemQuantityBySeller(
                              bundleSeller,
                              product._id,
                            )}
                            onQuantityChange={handleProductQuantity}
                            discountActive={discountActive}
                            showMiniNotification={showMiniNotification}
                            bundleSeller={bundleSeller}
                            source={bundleName}
                          />
                        ))}
                    </>
                  ) : null}
                </div>

                {discountActive && (
                  <div className={styles.bundleStatus}>
                    <del>
                      {`${country?.currencySymbol} ${formatAmount(
                        grossTotal,
                        true,
                      )}`}
                    </del>
                    <ins>
                      {`${country?.currencySymbol} ${formatAmount(
                        totalPrice,
                        true,
                      )}`}
                    </ins>
                  </div>
                )}
              </>
            )}
          </Drawer>
        </div>
      )}
    </>
  );
};

export default BundlePopup;
