import { useMutation } from '@apollo/client';
import React, { useState, useEffect, useContext } from 'react';
// import {
//   LanguageIcon,
//   CheckedRadio,
//   UnCheckedRadio,
// } from '@/assets/icons/desktop';

// Components
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import { makeStyles } from '@material-ui/core/styles';

// Utils
import Languages, {
  NativeLanguageNames,
  LanguageShortForms,
} from '@/services/translation';
import { changeLanguage } from '@/utils/analytics';
import { AuthUserContext } from '@/contexts/authUser';
import { getCookie, setCookie } from '@/utils/helper';
import { UPDATE_CUSTOMER_META } from '@/graphql/misc';
import { GqlContext } from '@/contexts/gql';
import { useTranslation } from 'react-i18next';
import { LanguageIcon } from '@/assets/icons/shared';
import SelectWithRadio from '../SelectWithRadio';

// styles
import styles from './style.module.css';

const languagesList = Object.keys(Languages);

const LanguageSelect = ({ useIcon }) => {
  const { site } = useContext(GqlContext);
  const [currentLang, setCurrentLang] = useState(languagesList[0]);
  const { i18n } = useTranslation();
  //   const classes = useStyles();
  const { authUser, userLang, setUserLang } = useContext(AuthUserContext);
  const [updateCustomerMeta] = useMutation(UPDATE_CUSTOMER_META);
  const switchLanguage = async (lang) => {
    console.log('Switching to ', lang);
    // return;
    if (authUser?.customerId) {
      try {
        await updateCustomerMeta({
          variables: {
            customerId: authUser.customerId,
            preferredLanguage: lang.slice(0, 2),
          },
        });
        changeLanguage(lang, authUser.customerId);
      } catch (error) {
        console.error('Error while updating customer info', error);
      }
    }
    setCookie('language', lang.slice(0, 2));
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    setUserLang(lang);
  };

  useEffect(() => {
    let cookieLanguage = '';
    Object.keys(LanguageShortForms).forEach((element) => {
      if (element.slice(0, 2) === getCookie('language')) {
        cookieLanguage = element;
      }
    });
    console.log(cookieLanguage);
    const preferredLanguage = site?.isAlerzo
      ? cookieLanguage || languagesList[0]
      : languagesList[0];
    setCurrentLang(preferredLanguage);
  }, [userLang]);

  return useIcon ? (
    <div>
      <SelectWithRadio
        background="#E4EEF5"
        values={languagesList}
        placeholder={<LanguageIcon />}
        symbol={<LanguageIcon className={styles.languageIcon} />}
        getValue={(v) => LanguageShortForms[v]}
        getLabel={(v) => NativeLanguageNames[v]}
        value={LanguageShortForms[currentLang]}
        onChange={(lang) => switchLanguage(lang)}
        color="#00559E"
      />
    </div>
  ) : (
    <div className={styles.desktopContainer}>
      <Select
        className={styles.select}
        data-test="component-styled-select"
        variant="outlined"
        value={currentLang}
      >
        {languagesList.map((lang) => (
          <MenuItem
            onClick={() => switchLanguage(lang)}
            value={lang}
            key={lang}
          >
            {NativeLanguageNames[lang]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
export default LanguageSelect;
