// Core Packages
import { useState, useEffect } from 'react';
import classnames from 'classnames';

// Custom Components
import Header from '@/components/Header';
// import SearchField from '@/components/SearchField';
import { LocationIcon, TimeIcon, RightChevron } from '@/assets/icons/register';
import { getRecentSearches, getLatLongAddrGooglePlaces } from '@/utils/helper';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LOCATION, RECENT_SEARCH_NAMES } from '@/constants/index';

import PlacesSearch from './PlacesSearch';
import GetLocation from './Map';

// Utils

// Styles
import styles from './style.module.css';

const LocationPickerAlt = ({
  onClose,
  onSave,
  // hideHeaderAndFooter,
  headerClassName,
  showBackButtonAction,
  isSmallDevice,
  isPopup,
  citySelectorSK = false,
}) => {
  const { t: translate } = useTranslation();

  const locationClasses = classnames('row j-start');
  const resultClasses = classnames(styles.result, 'row j-start');
  const [showMapScreen, setShowMapScreen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);

  const headerClassNames = headerClassName
    ? classnames(headerClassName, styles.header)
    : styles.header;

  const saveLocation = (
    lat = DEFAULT_LOCATION?.ug?.lat,
    long = DEFAULT_LOCATION?.ug?.long,
    address = '',
  ) => {
    onSave(lat, long, address);
    if (onClose) onClose();
  };

  const locations = getRecentSearches(RECENT_SEARCH_NAMES.locations);

  useEffect(async () => {
    const locationDetails = await getLatLongAddrGooglePlaces();
    setCurrentLocation(locationDetails);
  }, []);

  return (
    <>
      {showMapScreen ? (
        <GetLocation
          isSmallDevice={isSmallDevice}
          citySelectorSK={citySelectorSK}
          showBackButtonAction={!showBackButtonAction}
          onBack={() => setShowMapScreen(false)}
          onSave={(lat, long, address) => saveLocation(lat, long, address)}
        />
      ) : (
        <div>
          {isPopup ? null : (
            <Header
              title={translate('Store Location')}
              showBackButtonAction={showBackButtonAction}
              onClick={onClose}
              className={headerClassNames}
            />
          )}
          <PlacesSearch onSave={saveLocation} />

          <div className={styles.locationContainer}>
            <div className={locationClasses}>
              <LocationIcon className={styles.locationIcon} />
              <button
                type="button"
                className={styles.locationButton}
                onClick={() => setShowMapScreen(true)}
              >
                Use current location
              </button>
            </div>

            {currentLocation ? (
              <button
                type="button"
                onClick={() => {
                  console.log('Clicked', currentLocation);
                  saveLocation(currentLocation.lat, currentLocation.long);
                }}
                className={resultClasses}
              >
                <div className="col">
                  <p className={styles.resultName}>
                    {currentLocation.location}
                  </p>
                  <p className={styles.resultAddress}>
                    {currentLocation.address}
                  </p>
                </div>
                <RightChevron />
              </button>
            ) : null}
          </div>

          <div className={styles.resultGroup}>
            {locations.length > 0 && (
              <h4 className={styles.recentLocation}>RECENT LOCATION</h4>
            )}
            {locations.map((location, index) => (
              <button
                key={`${location.lat}_${location.long}_${location.address}_${index}`}
                type="button"
                onClick={() => saveLocation(location.lat, location.long)}
                className={resultClasses}
              >
                <TimeIcon />
                <div className="col">
                  <p className={styles.resultName}>{location.location}</p>
                  <p className={styles.resultAddress}>{location.address}</p>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LocationPickerAlt;
