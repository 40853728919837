// Core Packages
import classnames from 'classnames';
import Link from 'next/link';
import { CaretRightIcon } from '@/assets/icons/shared';

// Styles
import styles from './style.module.css';

export default function Menu({
  label, icon, className, href, subText,
}) {
  const menuClasses = classnames(
    'row j-space-between a-center',
    styles.menu,
    className,
  );

  return (
    <Link href={href}>
      <li className={menuClasses}>
        <span className="row j-start a-center">
          {icon}
          <span className={styles.label}>{label}</span>
        </span>
        <span className={styles.link}>
          {subText && <span className={styles.subText}>{subText}</span>}
          <CaretRightIcon />
        </span>
      </li>
    </Link>
  );
}
