/* eslint-disable no-nested-ternary */
// Core Packages
import { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import Link from 'next/link';

// Custom Components
import QuantityControl from '@/components/QuantityControl';
// import SellersPopupButton from '@/containers/SellersPopup/SellersPopupButton';
import { UnselectedAlerzoIcon } from '@/components/BottomNavigationBar/icons';
import { BundleIcon } from '@/assets/icons/home';
import OutOfStockButton from '@/components/OutOfStockButton';
// import Banner from '@/components/Banner';
import LoginForPriceBtn from '@/components/LoginForPriceBtn';
import ViewSimilarItemsButton from '@/containers/ViewSimilarItemsPopUp/ViewSimilarItemsButton';

// Utils
import { useTranslation } from 'react-i18next';
import {
  formatAmount,
  capitalizeText,
  imageSrcProvider,
  seoFriendlyParam,
} from '@/utils/helper';
import { AuthUserContext } from '@/contexts/authUser';
import { GqlContext } from '@/contexts/gql';
import { CartContext } from '@/contexts/cart';
import { RetailBlur, ShopBag, WholeSaleBlur } from '@/assets/icons/shared';

// Styles
import { SKNoImageIcon } from '@/assets/icons/cart';
import styles from './style.module.css';
/* eslint-disable camelcase */

const ItemsLeftRibbon = ({ className, quantity }) => {
  const { t: translate } = useTranslation();
  return (
    <div className={className}>
      <ShopBag />
      {`${quantity} ${translate('Left')}`}
    </div>
  );
};

export default function CompactProduct({
  className,
  product,
  source = '',
  cartQuantity,
  // onQuantityChange,
  // loadingType,
  showBundle,
  getItemQuantityForBundle,
  cartItems,
  // showMiniNotification,
  pageSource = '',
  grid,
  // sellerDetails,
}) {
  const { t: translate } = useTranslation();
  const { authUser } = useContext(AuthUserContext);
  const {
    getProductMaxQty,
    getProductPrice,
    getSellerDetails,
    doesProductHaveAnyStock,
    updateProductQuantity,
  } = useContext(CartContext);
  const {
    subCategory,
    brand,
    images,
    image,
    discount,
    variant,
    // minWholeSaleQty,
    stockValue = 30,
    bundle,
    // sellers,
  } = product;

  // eslint-disable-next-line max-len
  const refinedImageOriginalUrl = imageSrcProvider(image, images?.imageUrl);

  const noUser = !authUser;
  const [retailPrice, setRetailPrice] = useState(0);
  const [wholesalePrice, setWholesalePrice] = useState(0);
  const [seller, setSeller] = useState('');
  const [maxQty, setMaxQty] = useState(0);
  const [imageBroken, setImageBroken] = useState(false);
  const [minQtyForDiscount, setMinQtyForDiscount] = useState(undefined);
  // const [mobilePopupVisible, setMobilePopupVisible] = useState(false);
  // const [desktopPopupVisible, setDesktopPopupVisible] = useState(false);
  const [mobileVSPopupVisible, setMobileVSPopupVisible] = useState(false);
  const [desktopVSPopupVisible, setDesktopVSPopupVisible] = useState(false);
  const isThereAnyStock = doesProductHaveAnyStock(product);
  const outOfStock = !isThereAnyStock;
  const { site, country } = useContext(GqlContext);

  // const isMultiSeller = useMemo(() => product.sellers?.length > 1, [product]);

  // const bundle = useMemo(() => {
  //   const sellerBundle = sellers?.find((s) => s?.bundle?.bundleName);

  //   return sellerBundle?.bundle;
  // }, [sellers]);

  const containerClasses = classnames(
    'col a-stretch relative',
    styles.productContainer,
    className,
  );
  const cutOutPriceClasses = classnames(styles.cutOutPrice, {
    [styles.show]: !!discount,
  });
  const getProductInfoClasses = (...classes) => classnames(...classes);

  // suggested order for priority in case of overlapping conditions
  // is urgent > bundle > normal
  const firstHalfSelector = () => {
    if (stockValue > 0 && stockValue <= 10) return styles.pinkFirstHalf;
    if (!!bundle && !outOfStock && bundle.productType === 'BUNDLE') {
      return styles.greenFirstHalf;
    }
    return styles.greyFirstHalf;
  };

  const handleQuantityChange = (initialVal) => (quantity) => {
    if (authUser) {
      const qty = initialVal || quantity;

      updateProductQuantity(
        product,
        qty,
        product.sellerId || seller.sellerId,
        product.sellerName || seller.sellerName,
        pageSource,
      );
    }
  };

  const [activateBundleWholesale, setActivateBundleWholesale] = useState(false);
  const [activateWholeSale, setActivateWholesale] = useState(false);

  const handleBundleClicked = () => {
    if (!showBundle || !bundle) return;
    showBundle(bundle);
  };

  // const getClassNames = () => {
  //   let result;
  //   if (activateWholeSale) {
  //     result = classnames(styles.item, styles.wholeSalePriceActive);
  //   } else {
  //     result = classnames(styles.item, styles.wholeSalePriceInactive);
  //   }

  //   return result;
  // };

  // const getPromotionText = () => {
  //   let result;

  //   if (!activateWholeSale) {
  //     result = translate('Add {qty} for wholesale price').replace(
  //       '{qty}',
  //       minQtyForDiscount - cartQuantity,
  //     );
  //   } else {
  //     result = translate('WholeSale Price Activated');
  //   }

  //   return result;
  // };

  const refreshDiscount = () => {
    const { wholesaleActive, minDiscountQty } = getProductPrice(product);

    const shouldActivateWholesale = wholesaleActive || cartQuantity >= minDiscountQty;
    setMinQtyForDiscount(minDiscountQty);

    setActivateWholesale(shouldActivateWholesale);
  };

  useEffect(() => {
    if (!bundle?._id) return;
    const bundleCartStatus = getItemQuantityForBundle(bundle._id);

    setActivateBundleWholesale(bundleCartStatus?.activateWholesale);
  }, []);

  useEffect(() => {
    refreshDiscount();

    // const { price, retail, wholesale } = getProductPrice(product)
    const { retail, wholesale } = getProductPrice(product);
    const newMax = getProductMaxQty(product);

    // Handle pricing
    setRetailPrice(retail);
    setWholesalePrice(wholesale);

    // Handle quantity limits by getting smallest limiter value
    setMaxQty(newMax);
  }, [activateBundleWholesale, cartQuantity, product]);

  useEffect(() => {
    if (!bundle) return;
    const bundleCartStatus = getItemQuantityForBundle(bundle._id);
    setActivateBundleWholesale(bundleCartStatus?.activateWholesale);
  }, [cartItems]);

  useEffect(() => {
    refreshDiscount();
    const sellerDetails = getSellerDetails(product._id);
    if (sellerDetails) {
      setSeller(sellerDetails);
    }
  }, [product, cartItems]);

  return (
    <div className={containerClasses}>
      {!!bundle && !outOfStock && bundle.productType === 'BUNDLE' ? (
        <div
          onClick={handleBundleClicked}
          onKeyDown={handleBundleClicked}
          role="button"
          tabIndex={0}
          className={getProductInfoClasses(styles.bundleIcon)}
        >
          <div className={styles.bundleIconContainer}>
            <BundleIcon />
            <span>{translate('Bundle')}</span>
          </div>
        </div>
      ) : !noUser && outOfStock ? (
        <div className={styles.outOfStock}>Out of Stock</div>
      ) : null}
      <div className={firstHalfSelector()}>
        {stockValue > 0 && stockValue <= 10 && (
          <ItemsLeftRibbon
            className={styles.itemsLeftRibbon}
            quantity={stockValue}
          />
        )}

        <Link
          href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
            product.category,
          )}/${seoFriendlyParam(product.subCategory)}/${
            product._id
          }?source=${source}`}
        >
          <div className={getProductInfoClasses(styles.imageContainer)}>
            {refinedImageOriginalUrl?.length > 0 && !imageBroken ? (
              <img
                className={getProductInfoClasses(styles.image)}
                src={refinedImageOriginalUrl}
                // loading={loadingType ?? 'lazy'}
                alt={variant}
                onError={() => {
                  setImageBroken(true);
                }}
                onErrorCapture={() => setImageBroken(true)}
              />
            ) : site?.isSK ? (
              <div className={styles.skLogoContainer}>
                <SKNoImageIcon className={styles.skLogo} />
              </div>
            ) : site?.isAlerzo ? (
              <UnselectedAlerzoIcon
                className={getProductInfoClasses(
                  styles.image,
                  styles.alerzoLogo,
                )}
              />
            ) : (
              <UnselectedAlerzoIcon
                className={getProductInfoClasses(
                  styles.image,
                  styles.alerzoLogo,
                )}
              />
            )}

            {/* {cartQuantity
            && minWholeSaleQty
            && wholesalePrice
            // !isMultiSeller &&
            && !outOfStock
            && wholesalePrice !== retailPrice ? (
              <p className={getClassNames()}>{getPromotionText()}</p>
              ) : null} */}
          </div>
        </Link>
      </div>
      <div className={getProductInfoClasses(styles.title)}>
        <Link
          href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
            product.category,
          )}/${seoFriendlyParam(product.subCategory)}/${
            product._id
          }?source=${source}`}
        >
          {variant}
        </Link>
      </div>
      <div className={getProductInfoClasses(styles.info)}>
        {`${capitalizeText(subCategory)} \u2022 ${capitalizeText(brand)}`}
      </div>

      {/* {seller?.sellerName ? (
        <div className={styles.bannerContainer}>
          <Banner text={seller?.sellerName} />
          <SellersPopupButton
            product={product}
            mobilePopupVisible={mobilePopupVisible}
            desktopPopupVisible={desktopPopupVisible}
            setMobilePopupVisible={setMobilePopupVisible}
            setDesktopPopupVisible={setDesktopPopupVisible}
            useTextButton
            small
          />
        </div>
      ) : null} */}

      <div className={getProductInfoClasses('col', styles.pricing)}>
        {!noUser ? (
          !outOfStock ? (
            <>
              <span className={cutOutPriceClasses}>
                {`${country?.currencySymbol}  ${formatAmount(discount, true)}`}
              </span>
              {minQtyForDiscount
              && wholesalePrice
              && wholesalePrice !== retailPrice ? (
                <div className={getProductInfoClasses(styles.info)}>
                  <span
                    className={
                      !activateWholeSale ? styles.priceInactive : styles.price
                    }
                  >
                    {`${country?.currencySymbol} ${formatAmount(
                      wholesalePrice,
                      true,
                    )} `}
                  </span>

                  {translate('(Min. {qty} Items)').replace(
                    '{qty}',
                    minQtyForDiscount,
                  )}
                </div>
                ) : null}
              <div className="row j-space-between a-center">
                <div
                  className={
                    activateWholeSale && wholesalePrice !== retailPrice
                      ? styles.priceInactive
                      : styles.price
                  }
                >
                  {`${country?.currencySymbol} ${formatAmount(
                    retailPrice,
                    true,
                  )}`}
                </div>
                {cartQuantity ? (
                  // && !isMultiSeller
                  <div className={styles.totalPrice}>
                    {activateWholeSale
                      ? `${country?.currencySymbol} ${formatAmount(
                        cartQuantity * wholesalePrice,
                        true,
                      )}`
                      : `${country?.currencySymbol} ${formatAmount(
                        cartQuantity * retailPrice,
                        true,
                      )}`}
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <OutOfStockButton
              product={product}
              textClass={grid ? styles.notifyTextAlt : {}}
              buttonClass={grid ? styles.notifyButtonAlt : {}}
              // sellerId={product?.sellers?.map((s) => s.sellerId)}
              sellerId={[product.sellerId]}
            />
          )
        ) : (
          <>
            <WholeSaleBlur />
            <RetailBlur />
          </>
        )}
      </div>

      {!noUser ? (
        !outOfStock ? (
          <>
            {/* {cartQuantity
            &&
            !isMultiSeller
            ? ( */}
            <QuantityControl
              className={getProductInfoClasses()}
              value={cartQuantity}
              max={maxQty}
              useTypedInput={!cartQuantity}
              onChange={handleQuantityChange()}
              variant={variant}
              // showMiniNotification={showMiniNotification}
            />
            {/* ) : (
              <SellersPopupButton
                product={product}
                key={`${product._id}__seller`}
                mobilePopupVisible={mobilePopupVisible}
                desktopPopupVisible={desktopPopupVisible}
                setMobilePopupVisible={setMobilePopupVisible}
                setDesktopPopupVisible={setDesktopPopupVisible}
                pageSource={pageSource}
              />
            )} */}
          </>
        ) : (
          <ViewSimilarItemsButton
            product={product}
            mobilePopupVisible={mobileVSPopupVisible}
            desktopPopupVisible={desktopVSPopupVisible}
            mobileButtonClass={grid ? styles.viewSimilarButtonAlt : {}}
            setMobilePopupVisible={setMobileVSPopupVisible}
            setDesktopPopupVisible={setDesktopVSPopupVisible}
          />
        )
      ) : (
        <LoginForPriceBtn buttonClassOffset={grid ? styles.loginButton : {}} />
      )}
    </div>
  );
}
