// Core Packages
import { useState, useContext, useEffect } from 'react';
import Router from 'next/router';
import { useMutation } from '@apollo/client';

// Custom Components
import Dialog from '@/components/Dialog';

// Utils
import { NotificationContext } from '@/contexts/Notification';
import { UPDATE_NEW_USER } from '@/graphql/auth';
import {
  setLocationCookies,
  getCookie,
  decodeURIString,
  // removeCookies,
} from '@/utils/helper';
import { AuthUserContext } from '@/contexts/authUser';
import { GqlContext } from '@/contexts/gql';
import { useTranslation } from 'react-i18next';

import CitySelectionSK from './CitySelectionSK';
import CitySelectionAlerzo from './CitySelectionAlerzo';

// Styles
import styles from './style.module.css';

export const DialogContent = (props) => {
  const {
    // onClose,
    stateAndCities,
    cityId,
    stateId,
    stateName,
    cityName,
    landmark,
    landmarkId,
    warehouse,
    noTitle,
    // setLoaded,
  } = props;
  const { showMiniNotification } = useContext(NotificationContext);

  const { t: translate } = useTranslation();
  const { site } = useContext(GqlContext);
  const { authUser, warehouseId } = useContext(AuthUserContext);
  const [updateCustomer] = useMutation(UPDATE_NEW_USER);
  const [landmarks, setLandmarks] = useState([]);
  const [showMap, setShowMap] = useState(true);

  const [form, setForm] = useState({
    cityId,
    cityName,
    stateId,
    stateName,
    landmark,
    landmarkId,
    warehouse,
    reload: false,
    lat: 0,
    long: 0,
    address: '',
  });

  const [loading, setLoading] = useState(false);
  const setState = () => ({ stateId, stateName }) => {
    setLandmarks([]);
    setForm({
      stateId,
      stateName,
    });
  };

  const setCity = () => (city) => {
    const { cityId, cityName, Landmarks } = city;
    setLandmarks(Landmarks);
    setForm({
      ...form,
      cityId,
      cityName,
      landmark: '',
      landmarkId: '',
      warehouse: '',
    });

    // Reload the map
    setShowMap(false);
    setTimeout(() => {
      setShowMap(true);
    }, 100);
  };

  const handleSKSetForm = (payload) => {
    setForm({
      ...form,
      ...payload,
    });
  };

  const setLandmark = ({ landmarkId, landmarkName, warehouseId }) => {
    setForm({
      ...form,
      landmarkId,
      landmark: landmarkName,
      warehouse: warehouseId,
    });
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);

    const {
      cityId, cityName, stateId, stateName, warehouse,
    } = form;

    const payload = {
      cityId,
      cityName: decodeURIString(cityName),
      stateId,
      stateName: decodeURIString(stateName),
    };

    if (site?.isAlerzo) {
      payload.landmark = form.landmark;
      payload.landmarkId = form.landmarkId;
      if (warehouse) {
        payload.warehouse = warehouse;
      }
    } else {
      payload.lat = form.lat;
      payload.long = form.long;
      if (warehouse) {
        payload.warehouse = warehouse;
      }
      payload.address = form.address;
    }
    console.log('to be set', payload.warehouse);
    console.log('current warehouse id', warehouseId);
    setLocationCookies(payload);

    // Refetching warehouse and lat long(alerzo) on page load
    // if (authUser) {
    //   removeCookies([{ name: 'warehouse', path: '/' }]);

    //   // remove previous lat long if any(not set for alerzo in this component)
    //   if (site?.isAlerzo) {
    //     removeCookies([
    //       { name: 'lat', path: '/' },
    //       { name: 'long', path: '/' },
    //     ]);
    //   }
    // }
    // uncomment last 11 lines above to use nearest warehouse for alerzo

    // return;
    if (authUser) {
      try {
        await updateCustomer({
          variables: {
            customerId: authUser.customerId,
            ...payload,
            city: payload.cityName,
            state: payload.stateName,
          },
        });
      } catch (error) {
        console.error('Error while updating customer info', error);
      }
    }

    // Rolling back because if we do not reload the page, all the
    // data previously fetched using a different warehouse will remain the same

    // setLoaded(true);
    // setLoading(false);
    // return onClose();

    return Router.reload(window.location.pathname);
  };

  useEffect(() => {
    if (form.cityId && form.stateId && stateAndCities?.length) {
      const matchingState = stateAndCities.find((s) => s.stateId === form.stateId) || '';
      const matchingCity = matchingState?.Cities?.find((c) => c.cityId === form.cityId) || '';
      setLandmarks(matchingCity?.Landmarks || []);
    }
  }, [form.cityId, form.stateId, stateAndCities]);

  useEffect(async () => {
    const oldLandmark = await getCookie('landmarkId');
    const oldLandmarkName = await getCookie('landmark');
    const oldWarehouse = await getCookie('warehouse');
    const userLandmark = authUser?.Stores?.[0]?.landmarkId;
    const userLandmarkName = authUser?.Stores?.[0]?.landmark;
    const landmarkIdToUse = userLandmark || oldLandmark || '';
    const landmarkNameToUse = userLandmarkName || oldLandmarkName || '';

    setLandmark({
      landmarkId: landmarkIdToUse,
      landmarkName: landmarkNameToUse,
      warehouseId: oldWarehouse,
    });
  }, [authUser]);

  useEffect(() => {
    // Result is from SK Express form
    if (form?.reload) {
      if (!form?.warehouse) {
        // Warehouse is not within servicable area
        showMiniNotification({
          duration: 3000,
          text: 'Your location is outside of our service area! root',
          isError: true,
        });

        setForm({
          ...form,
          reload: false,
        });
        return;
      }
      handleSubmit();
    }
  }, [form]);

  return (
    <>
      {!noTitle ? (
        <h4 className={styles.title}>{translate('Delivery Location')}</h4>
      ) : null}
      {site?.isSK ? (
        <CitySelectionSK setForm={handleSKSetForm} loading={loading} />
      ) : (
        <CitySelectionAlerzo
          stateAndCities={stateAndCities}
          noTitle={noTitle}
          // Form data
          handleSubmit={handleSubmit}
          setLandmark={setLandmark}
          setCity={setCity}
          setState={setState}
          form={form}
          showMap={showMap}
          setLandmarks={setLandmarks}
          landmarks={landmarks}
          loading={loading}
        />
      )}
    </>
  );
};

const CitySelector = (props) => {
  const { onClose } = props;
  return (
    <Dialog onClose={onClose} containerClassName={styles.dialog}>
      <DialogContent {...props} />
    </Dialog>
  );
};

export default CitySelector;
