// Core Packages
import classnames from 'classnames';
import DesktopHeader from '@/components/Layout/DesktopHeader';
import DesktopFooter from '@/components/Layout/DesktopFooter';

// Styles
import styles from './style.module.css';

export default function PageWrapper({
  className,
  children = null,
  childrenOnly = false,
  childContainerClasses,
}) {
  const containerClasses = classnames(
    styles.container,
    // "col j-start a-stretch h-100 scroll-y",
    'col j-start a-stretch h-100 scroll-y',
    className,
  );

  const childContainer = classnames(
    styles.childrenContainer,
    childContainerClasses,
  );

  return (
    <>
      <div className={containerClasses}>
        {!childrenOnly && <DesktopHeader />}
        <div className={childContainer}>{children}</div>
        {!childrenOnly && <DesktopFooter />}
      </div>
    </>
  );
}
