import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styles from './style.module.css';

export default function ScrollToTop() {
  const scrollOnClick = () => {
    // window.scroll(0, 0);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={styles.scrollToTop}
      onClick={scrollOnClick}
      onKeyDown={scrollOnClick}
      role="button"
      tabIndex={0}
    >
      <KeyboardArrowUpIcon />
    </div>
  );
}
