/* eslint-disable max-len */
import React, { useContext } from 'react';
import classNames from 'classnames';

// Custom Components

// Utils
import { useTranslation } from 'react-i18next';
import { similarItems } from '@/utils/analytics';
import { AuthUserContext } from '@/contexts/authUser';
import ViewSimilarItemsPopUp from '../index';

// Styles
import styles from './style.module.css';

const ViewSimilarItemsButton = ({
  product,
  small,
  mobilePopupVisible,
  desktopPopupVisible,
  setMobilePopupVisible,
  setDesktopPopupVisible,
  mobileButtonClass,
}) => {
  const { t: translate } = useTranslation();
  const { authUser, warehouseId } = useContext(AuthUserContext);
  const desktopButton = classNames(styles.container, styles.largeScreenOnly);

  const mobileButton = classNames(
    styles.container,
    styles.smallScreenOnly,
    mobileButtonClass,
  );
  return (
    <>
      {mobilePopupVisible ? (
        <ViewSimilarItemsPopUp
          product={product}
          setOpen={setMobilePopupVisible}
        />
      ) : null}

      {desktopPopupVisible ? (
        <ViewSimilarItemsPopUp
          product={product}
          setOpen={setDesktopPopupVisible}
          useDesktopVersion
        />
      ) : null}
      <div className={styles.similarItemsContainer}>
        <button
          type="button"
          className={desktopButton}
          onClick={() => {
            similarItems(product._id, warehouseId, authUser.customerId);
            setDesktopPopupVisible(true);
          }}
          style={small ? { fontSize: '0.8em' } : {}}
        >
          <span>{translate('View Similar Items')}</span>
        </button>

        <button
          type="button"
          className={mobileButton}
          onClick={() => {
            similarItems(product._id, warehouseId, authUser.customerId);
            setMobilePopupVisible(true);
          }}
        >
          <span>{translate('View Similar Items')}</span>
        </button>
      </div>
    </>
  );
};

export default ViewSimilarItemsButton;
