import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';

const LoginForPriceBtn = ({ buttonClassOffset, pdpStyles = false }) => {
  const { t: translate } = useTranslation();
  const router = useRouter();
  const goToLogin = () => {
    router.push('/login', null, { shallow: true });
  };
  const buttonClass = pdpStyles
    ? classNames(styles.pdpContainer)
    : classNames(styles.container, buttonClassOffset);

  return (
    <button className={buttonClass} type="button" onClick={goToLogin}>
      <span className={styles.notifyPrompt}>
        {translate('Login to view price')}
      </span>
    </button>
  );
};

export default LoginForPriceBtn;
