/* eslint-disable no-nested-ternary */
// Core Packages
import {
  useState, useEffect, useContext, useMemo,
} from 'react';

// Next
import Link from 'next/link';

// Custom Components
import QuantityControl from '@/components/QuantityControl';
import OutOfStockButton from '@/components/OutOfStockButton';
import LoginForPriceBtn from '@/components/LoginForPriceBtn';

// Contexts
import { AuthUserContext } from '@/contexts/authUser';
import { GqlContext } from '@/contexts/gql';
import { CartContext } from '@/contexts/cart';
import { NotificationContext } from '@/contexts/Notification';

// Utils
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  formatAmount,
  capitalizeText,
  calcPromotionPrice,
  imageSrcProvider,
  seoFriendlyParam,
} from '@/utils/helper';

// Assets
import {
  UnlockedIcon,
  LockedIcon,
  WholesaleActiveIcon,
} from '@/assets/icons/bundle';
import { UnselectedAlerzoIcon } from '@/components/BottomNavigationBar/icons';
import { SKNoImageIcon } from '@/assets/icons/cart';
import { RetailBlur, WholeSaleBlur } from '@/assets/icons/shared';

// Styles
import styles from './style.module.css';

const BundleV2Product = ({
  product,
  cartQuantity,
  onQuantityChange,
  bundleSeller,
  bundleId,
  source = '',
}) => {
  const {
    subCategory, brand, image, images, variant, stockValue,
  } = product;
  const { t: translate } = useTranslation();

  // Context
  const { authUser } = useContext(AuthUserContext);
  const { site, country } = useContext(GqlContext);
  const { showMiniNotification } = useContext(NotificationContext);
  const {
    cartItems,
    getProductMaxQty,
    getProductPrice,
    doesProductHaveAnyStock,
    getItemQuantityForBundle,
  } = useContext(CartContext);

  // State
  const [activateWholeSale, setActivateWholesale] = useState(false);
  const [offerUnlocked, setOfferUnlocked] = useState(false);
  const [imageBroken, setImageBroken] = useState(false);
  const [retailPrice, setRetailPrice] = useState(0);
  const [retailPriceWithoutPromotion, setRetailPriceWithoutPromotion] = useState(0);
  const [wholesalePrice, setWholesalePrice] = useState(0);
  const [maxQty, setMaxQty] = useState(10);
  const [bundleDetails, setBundle] = useState({});
  const [sellerDetails, setSellerDetails] = useState({});
  const [minQtyForDiscount, setMinQtyForDiscount] = useState(undefined);
  const [mandatoryFactor, setMandatoryFactor] = useState(undefined);
  const noUser = !authUser;
  const isThereAnyStock = doesProductHaveAnyStock(product);
  const outOfStock = !isThereAnyStock;
  const refinedImageOriginalUrl = imageSrcProvider(image, images?.imageUrl);

  // Functionality
  const handleQuantityChange = (initialVal) => (quantity) => {
    if (authUser) {
      const qty = initialVal || quantity;
      onQuantityChange(
        product,
        qty,
        product.sellerId || sellerDetails?.sellerId,
        product.sellerName || sellerDetails?.sellerName,
      );
    }
  };

  const refreshDiscount = () => {
    const { wholesaleActive, minDiscountQty } = getProductPrice(
      product,
      bundleSeller,
    );

    setMinQtyForDiscount(minDiscountQty);

    setActivateWholesale(wholesaleActive);
  };

  useEffect(() => {
    const { activateWholesale, mandatoryQuantifier } = getItemQuantityForBundle(
      bundleId,
      bundleSeller,
    );

    setOfferUnlocked(activateWholesale);

    if (bundleDetails.mandatory) {
      setMandatoryFactor(mandatoryQuantifier);
    }
    refreshDiscount();
  }, [cartItems, bundleDetails]);

  useEffect(() => {
    const {
      retail, wholesale, wholesaleActive, minDiscountQty,
    } = getProductPrice(product, bundleSeller);
    const { retail: retailWithoutPromotion } = getProductPrice(
      product,
      null,
      null,
      true,
    );
    const newMax = getProductMaxQty(product);

    // Handle pricing
    setRetailPrice(retail);
    setWholesalePrice(wholesale);

    setRetailPriceWithoutPromotion(retailWithoutPromotion);

    // Handle quantity limits by getting smallest limiter value
    setMaxQty(newMax);

    // Handle Product Discount
    setMinQtyForDiscount(minDiscountQty);
    setActivateWholesale(wholesaleActive);

    const bundle = product.bundle || {};
    setBundle(bundle);

    const sellers = product.sellers || [];
    const sellerDetails = sellers.find((s) => s.sellerId === bundleSeller);

    setSellerDetails({
      sellerId: bundleSeller,
      sellerName: sellerDetails?.seller?.sellerName,
    });
  }, [product]);

  const maxDiscountedQuantity = useMemo(
    () => Math.min(maxQty, stockValue, bundleDetails.maxDiscountedQty),
    [bundleDetails, maxQty],
  );

  const mandatoryQuantity = useMemo(() => {
    if (offerUnlocked && !!mandatoryFactor && !!bundleDetails.mandatory) {
      return Math.min(
        maxQty,
        stockValue,
        mandatoryFactor * bundleDetails.maxDiscountedQty,
      );
    }
    return false;
  }, [offerUnlocked, bundleDetails, mandatoryFactor, maxDiscountedQuantity]);

  const bundleDiscountText = useMemo(() => {
    if (bundleDetails.discount) {
      if (bundleDetails?.discountType === 'ABSOLUTE') {
        return `${country?.currencySymbol} ${
          bundleDetails.discount
        } OFF | Max Qty ${
          !bundleDetails.mandatory
            ? maxDiscountedQuantity
            : mandatoryQuantity || bundleDetails.maxDiscountedQty
        }`;
      }
      if (bundleDetails?.discountType === 'PERCENTAGE') {
        return `${bundleDetails.discount}% OFF | Max Qty ${
          !bundleDetails.mandatory
            ? maxDiscountedQuantity
            : mandatoryQuantity || bundleDetails.maxDiscountedQty
        }`;
      }
    } else {
      return `Max Qty ${
        !bundleDetails.mandatory
          ? maxDiscountedQuantity
          : mandatoryQuantity || bundleDetails.maxDiscountedQty
      }`;
    }
    return '';
  }, [bundleDetails, mandatoryQuantity, maxDiscountedQuantity]);

  const discountedPrice = useMemo(
    () => calcPromotionPrice(retailPriceWithoutPromotion, bundleDetails),
    [bundleDetails, retailPriceWithoutPromotion],
  );

  const promotionalProduct = useMemo(() => {
    if (offerUnlocked) {
      const remainingQuantity = !bundleDetails.mandatory
        ? maxDiscountedQuantity - cartQuantity
        : mandatoryQuantity - cartQuantity;
      return {
        ...bundleDetails,
        remainingQuantity,
        promotedQuantity: !bundleDetails.mandatory
          ? remainingQuantity <= 0
            ? maxDiscountedQuantity
            : cartQuantity
          : mandatoryQuantity,

        regularQuantity:
          remainingQuantity <= 0
            ? !bundleDetails.mandatory
              ? cartQuantity - maxDiscountedQuantity
              : cartQuantity - mandatoryQuantity
            : 0,
      };
    }
    return false;
  }, [
    bundleDetails,
    offerUnlocked,
    cartQuantity,
    mandatoryQuantity,
    maxDiscountedQuantity,
  ]);

  const getPromotionText = () => {
    let result;
    if (
      !!promotionalProduct?.promotedQuantity
      && promotionalProduct?.promotedQuantity < maxDiscountedQuantity
    ) {
      result = (
        <>
          <span>
            {`
             ${
               maxDiscountedQuantity - promotionalProduct?.promotedQuantity
             } ${translate('more')} `}
          </span>
          {translate('available on offer')}
        </>
      );
    } else if (!activateWholeSale) {
      result = (
        <>
          {translate('Add')}
          <span>
            {` ${minQtyForDiscount - cartQuantity} ${translate('more')} `}
          </span>
          {translate('for wholesale price')}
        </>
      );
    } else if (activateWholeSale) {
      result = (
        <>
          <WholesaleActiveIcon className={styles.wholesaleIcon} />
          <span>{translate('Wholesale price active')}</span>
        </>
      );
    }

    return result;
  };

  // Styles
  const containerClasses = classNames('col', styles.productContainer);
  const bundleCardClasses = classNames(
    'col',
    (!!cartQuantity && offerUnlocked) || (bundleDetails.mandatory && !noUser)
      ? styles.cartSense
      : '',
  );

  const discountStatusClasses = classNames(styles.topBanner);

  const getProductInfoClasses = (...classes) => classNames(...classes);

  const getClassNames = () => {
    let result;
    if (activateWholeSale && promotionalProduct?.regularQuantity > 0) {
      result = classNames(styles.item, styles.wholeSalePriceActive);
    } else {
      result = classNames(styles.item, styles.wholeSalePriceInactive);
    }
    return result;
  };
  const promotedPriceClasses = classNames(
    'row a-center j-space-between',
    promotionalProduct.regularQuantity ? styles.cartSense : '',
  );
  const totalPromotedPriceClasses = classNames(
    promotionalProduct.promotedQuantity < maxDiscountedQuantity
      ? styles.totalPriceAlt
      : styles.totalPrice,
  );

  return (
    <div className={containerClasses}>
      <div className={bundleCardClasses}>
        <div className="row">
          <Link
            href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
              product.category,
            )}/${seoFriendlyParam(product.subCategory)}/${
              product._id
            }?source=${source}`}
          >
            <div className={getProductInfoClasses('row', styles.imageSection)}>
              {refinedImageOriginalUrl && !imageBroken ? (
                <img
                  className={styles.image}
                  src={refinedImageOriginalUrl}
                  alt={variant}
                  onError={() => {
                    setImageBroken(true);
                  }}
                  onErrorCapture={() => setImageBroken(true)}
                />
              ) : site?.isSK ? (
                <div className={styles.skLogoContainer}>
                  <SKNoImageIcon className="row a-center j-center" />
                </div>
              ) : site?.isAlerzo ? (
                <UnselectedAlerzoIcon
                  className={getProductInfoClasses(
                    styles.image,
                    styles.alerzoLogo,
                  )}
                />
              ) : (
                <UnselectedAlerzoIcon
                  className={getProductInfoClasses(
                    styles.image,
                    styles.alerzoLogo,
                  )}
                />
              )}
            </div>
          </Link>

          <div className={styles.infoSection}>
            <div className={styles.title}>
              <Link
                href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
                  product.category,
                )}/${seoFriendlyParam(product.subCategory)}/${
                  product._id
                }?source=${source}`}
              >
                {variant}
              </Link>
            </div>
            <div className={styles.info}>
              {`${capitalizeText(subCategory || '')} \u2022 ${capitalizeText(
                brand,
              )}`}
            </div>
            {!noUser ? (
              !outOfStock ? (
                <div className={styles.pricing}>
                  {!!bundleDetails.discount && (
                    <div
                      className={styles.inactivePrice}
                      style={{ textDecoration: 'line-through' }}
                    >
                      {`${country?.currencySymbol} ${formatAmount(
                        retailPriceWithoutPromotion,
                        true,
                      )}`}
                    </div>
                  )}
                  <div className={styles.activePrice}>
                    {`${country?.currencySymbol} ${formatAmount(
                      discountedPrice,
                      true,
                    )}`}
                  </div>
                </div>
              ) : (
                <div className={styles.outOfStock}>Out of Stock</div>
              )
            ) : (
              <div className="col">
                <WholeSaleBlur className={styles.wholesaleLogin} />
                <RetailBlur className={styles.retailLogin} />
              </div>
            )}
          </div>
          <div
            className={
              !noUser ? styles.quantitySection : styles.loginButtonContainer
            }
          >
            {!noUser ? (
              offerUnlocked ? (
                !outOfStock && (
                  <QuantityControl
                    className={styles.quantityControlSize}
                    value={cartQuantity}
                    max={maxQty}
                    short
                    useTypedInput={!cartQuantity}
                    onChange={handleQuantityChange()}
                    variant={variant}
                    showMiniNotification={showMiniNotification}
                    disableMinusButton={
                      bundleDetails.mandatory
                        ? cartQuantity === mandatoryQuantity
                        : false
                    }
                  />
                )
              ) : (
                !outOfStock && (
                  <QuantityControl
                    className={styles.quantityControlSize}
                    value={cartQuantity}
                    short
                    useTypedInput={!cartQuantity}
                    disableAllButtons
                    disableInput
                  />
                )
              )
            ) : (
              <LoginForPriceBtn buttonClassOffset={styles.loginButton} />
            )}
          </div>
        </div>
        {!noUser && !outOfStock && (
          <div className={discountStatusClasses}>
            <div className={styles.discountMarker}>{bundleDiscountText}</div>
            <div className="row a-center">
              <div className={styles.lockIconContainer}>
                {offerUnlocked ? (
                  <UnlockedIcon className={styles.lockIcon} size={12} />
                ) : (
                  <LockedIcon className={styles.lockIcon} size={12} />
                )}
              </div>
              <span>
                {' '}
                {offerUnlocked ? 'Unlocked' : 'Locked'}
              </span>
            </div>
          </div>
        )}
      </div>
      {!noUser ? (
        !outOfStock ? (
          offerUnlocked ? (
            !!promotionalProduct?.promotedQuantity && (
              <div className={promotedPriceClasses}>
                {promotionalProduct?.promotedQuantity
                < maxDiscountedQuantity ? (
                  <>
                    {/* <span className={styles.promotedPriceMarker}>Promoted</span> */}
                    <span className={getClassNames()}>
                      {getPromotionText()}
                    </span>
                  </>
                  ) : promotionalProduct.regularQuantity <= 0
                  && !activateWholeSale
                  && wholesalePrice !== retailPrice ? (
                      !bundleDetails.mandatory ? (
                        <span className={getClassNames()}>
                          Add more to get additonal discounts
                        </span>
                      ) : (
                        <div className="row a-center wrap">
                          <span className={styles.promotedPriceMarker}>
                            Mandatory
                          </span>
                          {!activateWholeSale && (
                          <span className={getClassNames()}>
                            Add more to get additonal discounts
                          </span>
                          )}
                        </div>
                      )
                    ) : !bundleDetails.mandatory ? (
                      <>
                        <span className={styles.promotedPriceMarker}>
                          Promoted Price
                        </span>
                        {/* {activateWholeSale && <span>🎉🎉</span>} */}
                      </>
                    ) : (
                      <div className="row a-center wrap">
                        <span className={styles.promotedPriceMarker}>
                          Mandatory
                        </span>
                      </div>
                    )}
                <span className={totalPromotedPriceClasses}>
                  {`${country?.currencySymbol} ${formatAmount(
                    promotionalProduct.promotedQuantity * discountedPrice,
                    true,
                  )}`}
                </span>
              </div>
            )
          ) : (
            <div className="row a-center wrap">
              {bundleDetails.mandatory && (
                <span className={styles.promotedPriceMarker}>Mandatory</span>
              )}
              {!!cartQuantity && !!bundleDetails.discount && (
                <span className={styles.inactivePrice}>
                  Oops! Re-Activate bundle to unlock best offers
                </span>
              )}
            </div>
          )
        ) : (
          <OutOfStockButton
            product={product}
            sellerId={[product.sellerId]}
            buttonClass={styles.notifyButton}
          />
        )
      ) : null}
      {!noUser && !outOfStock && (
        <>
          {promotionalProduct && promotionalProduct.regularQuantity > 0 && (
            <div className="row">
              <div className="col flex-1">
                <div className="row a-center ">
                  {/* {promotionalProduct.regularQuantity > 0 &&
                    !activateWholeSale && (
                      <span className={styles.regularPriceMarker}>Regular</span>
                    )} */}
                  {!outOfStock
                    && minQtyForDiscount
                    && retailPrice !== wholesalePrice && (
                      <div className={getClassNames()}>
                        {getPromotionText()}
                      </div>
                  )}
                </div>

                <div style={{ paddingLeft: '3px', paddingTop: '8px' }}>
                  <div
                    className={
                      activateWholeSale
                      && wholesalePrice
                      && wholesalePrice !== retailPrice
                        ? styles.inactivePrice
                        : styles.activePrice
                    }
                  >
                    {`${country?.currencySymbol}  ${formatAmount(
                      retailPrice,
                      true,
                    )}  ${wholesalePrice === retailPrice ? '/-' : ''}`}
                  </div>
                  {minQtyForDiscount
                    && wholesalePrice
                    && wholesalePrice !== retailPrice && (
                      <div className={styles.infoAlt}>
                        <span
                          className={
                            !activateWholeSale
                              ? styles.inactivePrice
                              : styles.activePrice
                          }
                        >
                          {`${country?.currencySymbol}  ${formatAmount(
                            wholesalePrice,
                            true,
                          )} `}
                        </span>

                        {translate('(Min. {qty} Items)').replace(
                          '{qty}',
                          minQtyForDiscount,
                        )}
                      </div>
                  )}
                </div>
              </div>
              {promotionalProduct.regularQuantity > 0 && (
                <div
                  className={
                    activateWholeSale && wholesalePrice !== retailPrice
                      ? styles.totalPrice
                      : styles.totalPriceAlt
                  }
                >
                  {`${country?.currencySymbol} ${
                    activateWholeSale
                      ? formatAmount(
                        wholesalePrice * promotionalProduct.regularQuantity,
                        true,
                      )
                      : formatAmount(
                        retailPrice * promotionalProduct.regularQuantity,
                        true,
                      )
                  }`}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BundleV2Product;
