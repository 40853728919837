/* eslint-disable no-nested-ternary */
// Core Packages
import React, { useContext, useEffect, useState } from 'react';

// Next
import Link from 'next/link';

// Custom Components
import QuantityControl from '@/components/QuantityControl';
import OutOfStockButton from '@/components/OutOfStockButton';
import LoginForPriceBtn from '@/components/LoginForPriceBtn';

// Contexts
import { AuthUserContext } from '@/contexts/authUser';
import { CartContext } from '@/contexts/cart';
import { GqlContext } from '@/contexts/gql';

// Utils
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  formatAmount,
  capitalizeText,
  imageSrcProvider,
  seoFriendlyParam,
} from '@/utils/helper';

// Assets
import { RetailBlur, WholeSaleBlur } from '@/assets/icons/shared';
import { UnselectedAlerzoIcon } from '@/components/BottomNavigationBar/icons';
import { SKNoImageIcon } from '@/assets/icons/cart';
import { WholesaleActiveIcon } from '@/assets/icons/bundle';

// Styles
import styles from './style.module.css';

const BundleProduct = ({
  product,
  bundleActivator,
  cartQuantity,
  onQuantityChange,
  discountActive,
  showMiniNotification,
  bundleSeller,
  source = '',
}) => {
  const {
    subCategory, brand, image, images, variant, minWholeSaleQty,
  } = product;
  const { bundleType } = product.bundle;
  const { t: translate } = useTranslation();

  // Context
  const { authUser } = useContext(AuthUserContext);
  const { site, country } = useContext(GqlContext);
  const {
    getProductMaxQty,
    getProductPrice,
    doesProductHaveAnyStock,
    getSellerDetails,
  } = useContext(CartContext);

  // States
  const [activateWholeSale, setActivateWholesale] = useState(false);
  const [retailPrice, setRetailPrice] = useState(0);
  const [wholesalePrice, setWholesalePrice] = useState(0);
  const [maxQty, setMaxQty] = useState(10);
  const [imageBroken, setImageBroken] = useState(false);
  const [minQtyForDiscount, setMinQtyForDiscount] = useState(undefined);
  const isThereAnyStock = doesProductHaveAnyStock(product);
  const outOfStock = !isThereAnyStock;
  const sellerDetails = getSellerDetails(product._id, bundleSeller);
  const noUser = !authUser;
  const refinedImageOriginalUrl = imageSrcProvider(image, images?.imageUrl);

  // Functionality
  const handleQuantityChange = (initialVal) => (quantity) => {
    if (authUser) {
      const qty = initialVal || quantity;
      onQuantityChange(
        product,
        qty,
        product.sellerId || sellerDetails?.sellerId,
        product.sellerName || sellerDetails?.sellerName,
      );
    }
  };

  const getPromotionText = () => {
    let result;
    if (!activateWholeSale) {
      result = (
        <>
          {translate('Add')}
          {bundleType === 'WHOLESALE' ? (
            <span>
              {` ${Math.min(
                minQtyForDiscount - cartQuantity,
                bundleActivator,
              )} ${translate('more')} `}
            </span>
          ) : (
            <span>
              {` ${minQtyForDiscount - cartQuantity} ${translate('more')} `}
            </span>
          )}
          {translate('for wholesale price')}
        </>
      );
    } else if (activateWholeSale) {
      result = (
        <>
          <WholesaleActiveIcon className={styles.wholesaleIcon} />
          <span>{translate('Wholesale price active')}</span>
        </>
      );
    }

    return result;
  };

  useEffect(() => {
    const {
      retail, wholesale, wholesaleActive, minDiscountQty,
    } = getProductPrice(product, bundleSeller);
    const newMax = getProductMaxQty(product);

    // Handle pricing
    setRetailPrice(retail);
    setWholesalePrice(wholesale);
    setMaxQty(newMax);

    // Handle Product Discount
    setMinQtyForDiscount(minDiscountQty);
    setActivateWholesale(wholesaleActive);
  }, [discountActive, product, cartQuantity]);

  // Styles
  const containerClasses = classnames('col', styles.productContainer);
  const bundleCardClasses = classnames(
    'row',
    !!cartQuantity || (!!outOfStock && !noUser) ? styles.cartSense : '',
  );
  const getProductInfoClasses = (...classes) => classnames(...classes);

  const getClassNames = () => {
    let result;
    if (activateWholeSale) {
      result = classnames(styles.item, styles.wholeSalePriceActive);
    } else {
      result = classnames(styles.item, styles.wholeSalePriceInactive);
    }
    return result;
  };

  return (
    <div className={containerClasses}>
      <div className={bundleCardClasses}>
        <Link
          href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
            product.category,
          )}/${seoFriendlyParam(product.subCategory)}/${
            product._id
          }?source=${source}`}
        >
          <div className={getProductInfoClasses('row', styles.imageSection)}>
            {refinedImageOriginalUrl && !imageBroken ? (
              <img
                className={styles.image}
                src={refinedImageOriginalUrl}
                alt={variant}
                onError={() => {
                  setImageBroken(true);
                }}
                // layout="fill"
                // objectFit="contain"
                // sizes="4rem"
                onErrorCapture={() => setImageBroken(true)}
              />
            ) : site?.isSK ? (
              <div className={styles.skLogoContainer}>
                <SKNoImageIcon className="row a-center j-center" />
              </div>
            ) : site?.isAlerzo ? (
              <UnselectedAlerzoIcon
                className={getProductInfoClasses(
                  styles.image,
                  styles.alerzoLogo,
                )}
              />
            ) : (
              <UnselectedAlerzoIcon
                className={getProductInfoClasses(
                  styles.image,
                  styles.alerzoLogo,
                )}
              />
            )}
          </div>
        </Link>

        <div className={styles.infoSection}>
          <div className={styles.title}>
            <Link
              href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
                product.category,
              )}/${seoFriendlyParam(product.subCategory)}/${
                product._id
              }?source=${source}`}
            >
              {variant}
            </Link>
          </div>
          <div className={styles.info}>
            {`${capitalizeText(subCategory || '')} \u2022 ${capitalizeText(
              brand,
            )}`}
          </div>

          {!noUser ? (
            !outOfStock ? (
              <div className={styles.pricing}>
                <div
                  className={
                    activateWholeSale
                    && wholesalePrice
                    && wholesalePrice !== retailPrice
                      ? styles.inactivePrice
                      : styles.activePrice
                  }
                >
                  {`${country?.currencySymbol} ${formatAmount(
                    retailPrice,
                    true,
                  )}`}
                </div>
                {minQtyForDiscount
                  && wholesalePrice
                  && retailPrice !== wholesalePrice && (
                    <div className={styles.infoAlt}>
                      <span
                        className={
                          !activateWholeSale
                            ? styles.inactivePrice
                            : styles.activePrice
                        }
                      >
                        {`${country?.currencySymbol} ${formatAmount(
                          wholesalePrice,
                          true,
                        )} `}
                      </span>
                      <span
                        style={
                          discountActive && bundleType === 'WHOLESALE'
                            ? { textDecoration: 'line-through' }
                            : {}
                        }
                      >
                        {translate('(Min. {qty} Items)').replace(
                          '{qty}',
                          minQtyForDiscount,
                        )}
                      </span>
                    </div>
                )}
              </div>
            ) : (
              <div className={styles.outOfStock}>Out of Stock</div>
            )
          ) : (
            <div className="col">
              <WholeSaleBlur className={styles.wholesaleLogin} />
              <RetailBlur className={styles.retailLogin} />
            </div>
          )}
        </div>
        <div
          className={
            !noUser ? styles.quantitySection : styles.loginButtonContainer
          }
        >
          {!noUser ? (
            !outOfStock && (
              <QuantityControl
                className={styles.quantityControlSize}
                value={cartQuantity}
                max={maxQty}
                short
                useTypedInput={!cartQuantity}
                onChange={handleQuantityChange()}
                variant={variant}
                showMiniNotification={showMiniNotification}
              />
            )
          ) : (
            <LoginForPriceBtn buttonClassOffset={styles.loginButton} />
          )}
        </div>
      </div>

      <div className="row a-center j-space-between">
        {!noUser ? (
          !outOfStock ? (
            <>
              {!!cartQuantity
                && minWholeSaleQty
                && wholesalePrice
                && retailPrice !== wholesalePrice && (
                  <div className={getClassNames()}>{getPromotionText()}</div>
              )}
              {!!cartQuantity && (
                <div
                  className={
                    activateWholeSale && wholesalePrice !== retailPrice
                      ? styles.totalPrice
                      : styles.totalPriceAlt
                  }
                >
                  {`${country?.currencySymbol} ${
                    activateWholeSale
                      ? formatAmount(cartQuantity * wholesalePrice, true)
                      : formatAmount(cartQuantity * retailPrice, true)
                  }`}
                </div>
              )}
            </>
          ) : (
            <OutOfStockButton
              product={product}
              sellerId={[product.sellerId]}
              buttonClass={styles.notifyButton}
            />
          )
        ) : null}
      </div>
    </div>
  );
};

export default BundleProduct;
