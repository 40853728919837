// Core Packages
import { useEffect, useState, useContext } from 'react';
import classnames from 'classnames';
import { NotificationContext } from '@/contexts/Notification';

// Utils
import { useTranslation } from 'react-i18next';

// Styles
import styles from './style.module.css';

export default function QuantityControl(props) {
  const {
    className,
    value = 1,
    max = 0,
    onChange,
    useTypedInput,
    variant,
    disableMaxButton = false,
    short,
    disableAllButtons = false,
    disableInput = false,
    disableMinusButton = false,
  } = props;
  const { t: translate } = useTranslation();
  const { showMiniNotification } = useContext(NotificationContext);
  const [quantity, setQuantity] = useState(value);
  const [typedQuantity, setTypedQuantity] = useState(value || '');
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const containerClasses = classnames(
    'row j-start a-center',
    value ? styles.container : styles.containerAlt,
    className,
  );
  const quantityClasses = classnames(
    'row j-center a-center flex-1 h-100',
    styles.quantity,
    className,
  );
  const buttonClasses = classnames(
    styles.button,
    disableMaxButton && max && quantity >= max ? styles.disabledButton : '',
  );

  const quantityInputClasses = classnames(
    'flex-1 h-100 w-100',
    styles.quantityInput,
    className,
  );

  const handleQtyError = () => {
    showMiniNotification({
      duration: 3000,
      text: translate('Only {quantity} quantity is available for {variant}')
        .replace('{quantity}', max)
        .replace('{variant}', variant),
      variant: 'topCenter',
    });
  };

  const handleQuantityChange = (newValue) => {
    if (newValue >= 0 && newValue <= max) {
      if (newValue !== value) {
        setQuantity(newValue);
        onChange(newValue);
      }
    } else {
      handleQtyError();
    }
  };
  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      handleQuantityChange(typedQuantity);
    }
  };
  const handleTypedQuantityChange = (val) => {
    if (!val) {
      setTypedQuantity('');
      return;
    }

    const newValue = Number(val);

    const qtyAcceptable = newValue > 0 && newValue <= max;

    if (Number.isNaN(Number(value))) return;

    if (qtyAcceptable) {
      setTypedQuantity(newValue);
    } else if (newValue === 0) {
      setTypedQuantity('');
    } else {
      handleQtyError();
    }
  };

  useEffect(() => {
    if (!initialLoadDone) return;
    setQuantity(value);
    setTypedQuantity(value > 0 ? value : '');
  }, [value]);

  useEffect(() => {
    setInitialLoadDone(true);
  }, []);

  return useTypedInput ? (
    <div className={containerClasses}>
      <input
        placeholder={short ? translate('Qty') : translate('Enter Qty')}
        className={quantityInputClasses}
        value={typedQuantity}
        onChange={(e) => handleTypedQuantityChange(e.target.value)}
        disabled={disableInput}
      />
      <button
        className={styles.buttonAlt}
        type="button"
        onClick={() => (typedQuantity ? handleQuantityChange(Number(typedQuantity)) : null)}
        disabled={disableAllButtons}
      >
        Add
      </button>
    </div>
  ) : (
    <div className={containerClasses}>
      <button
        className={styles.button}
        type="button"
        onClick={() => handleQuantityChange(quantity - 1)}
        disabled={disableAllButtons || disableMinusButton}
      >
        &#65293;
      </button>
      <input
        type="number"
        className={quantityClasses}
        value={typedQuantity}
        onChange={(e) => handleTypedQuantityChange(e.target.value)}
        onBlur={() => handleQuantityChange(typedQuantity)}
        onKeyPress={handleKeydown}
        disabled={disableInput}
      />
      <button
        className={buttonClasses}
        type="button"
        onClick={() => handleQuantityChange(quantity + 1)}
        disabled={
          (disableMaxButton && max && quantity >= max) || disableAllButtons
        }
      >
        &#43;
      </button>
    </div>
  );
}
