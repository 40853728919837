import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import Link from 'next/link';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import ClickAwayListener from '@/components/ClickAwayListener';
import { useRouter } from 'next/router';

import { GET_PRODUCT_FILTERS } from '@/graphql/products';
import styles from './style.module.css';

const DesktopCategory = ({
  category,
  // setIsCategoryOpen
}) => {
  const router = useRouter();
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  // const displayDropdownOnLeft = number > 3;

  const marginRef = useRef(null);
  const toggleVisibility = (bool) => {
    setCategoryOpen(bool);
    // setIsCategoryOpen(bool);
  };
  // eslint-disable-next-line max-len
  const [getSearchResults, { data: getProductFiltersApp }] = useLazyQuery(GET_PRODUCT_FILTERS);
  const filters = {
    Categories:
      category?.searchTerms?.[0] === 'Dairies & Snacks'
        ? ['Dairies and Snacks', category.categoryName]
        : [category?.searchTerms?.[0], category.categoryName],
    SubCategories: [],
    brands: [],
    discounts: [],
  };

  useEffect(() => {
    const categories = filters.Categories.filter((c) => !!c);
    getSearchResults({
      variables: {
        categories,
        subCategories: filters.SubCategories,
        brands: filters.brands,
      },
    });
  }, [category]);

  useEffect(() => {
    if (!getProductFiltersApp) return;
    const {
      getProductFiltersApp: { brands, subCategories },
    } = getProductFiltersApp;
    setBrandList(brands);
    setSubCatList(subCategories);
  }, [getProductFiltersApp]);

  return (
    <div
      className={styles.container}
      style={categoryOpen ? { background: '#ffffff' } : {}}
      onMouseLeave={() => toggleVisibility(false)}
      onMouseEnter={() => toggleVisibility(true)}
      role="button"
      tabIndex={0}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          router.push(
            `/category/${category?.categoryName}?cats=${category?.categoryName}&sort=newarrival`,
          );
        }}
        onKeyDown={() => {
          router.push(
            `/category/${category?.categoryName}?cats=${category?.categoryName}&sort=newarrival`,
          );
        }}
        ref={marginRef}
        className={styles.categoryTitle}
      >
        {category?.categoryName}
      </div>
      {categoryOpen ? (
        <ClickAwayListener onClickAway={() => toggleVisibility(false)}>
          <div
            className={styles.dropdown}
            onClick={() => toggleVisibility(false)}
            onKeyDown={() => toggleVisibility(false)}
            role="button"
            tabIndex={0}
            style={
              window.innerWidth
                - marginRef?.current?.getBoundingClientRect().right
              <= 480
                ? {
                  right:
                      window.innerWidth
                      - marginRef?.current?.getBoundingClientRect().right,
                }
                : { left: marginRef?.current?.getBoundingClientRect().left }
            }
          >
            <div className={styles.categoryListing}>
              <div className={styles.categoryListingContainer}>
                <h3>SUB CATEGORY</h3>
                <div className={styles.listing}>
                  {subCatList.map((subCat) => (
                    <Link
                      key={subCat?.key}
                      href={`/category/${category.categoryName}?cats=${category.categoryName}&subCat=${subCat?.key}&sort=newarrival`}
                      // href="/category/[category]?sort=newarrival"
                    >
                      <p className={styles.listingItem} key={subCat?.key}>
                        {subCat?.key}
                      </p>
                    </Link>
                  ))}
                </div>
              </div>

              <div className={styles.categoryListingContainer}>
                <h3>TOP BRANDS</h3>
                <div className={styles.listing}>
                  {brandList.map((brand) => (
                    <Link
                      key={brand?.key}
                      href={`/category/${category.categoryName}?cats=${category.categoryName}&brand=${brand?.key}&sort=newarrival`}
                      // href="/category/[category]?sort=newarrival"
                    >
                      <p className={styles.listingItem} key={brand?.key}>
                        {brand?.key}
                      </p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  );
};

export default DesktopCategory;
