import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { BlueDropdownIcon } from '@/assets/icons/desktop';
import { ChevronRightIcon } from '@/components/MiniNotification';

import { GET_PRODUCT_CATEGORIES } from '@/graphql/products';
import { getCookie } from '@/utils/helper';
import DesktopCategory from './DesktopCategory';
import AllCategoryDropdown from './DesktopCategory/AllCategoryDropdown';
import styles from './style.module.css';

const DesktopCategories = () => {
  const [getCategories, { data: categoriesData }] = useLazyQuery(
    GET_PRODUCT_CATEGORIES,
  );
  // const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const newCategories = categoriesData?.fetchProductCategory ?? [];
    setCategories(newCategories);
  }, [categoriesData]);

  useEffect(() => {
    const stateId = getCookie('stateId');
    const cityId = getCookie('cityId');

    getCategories({
      variables: {
        stateId,
        cityId,
      },
    });
  }, []);

  return (
    <div className={styles.container}>
      <AllCategoryDropdown
        content={(
          <p className={styles.containerTitle}>
            <span>ALL CATEGORIES</span>
            <BlueDropdownIcon className={styles.dropdownIcon} />
          </p>
        )}
      />
      <div className={styles.categories}>
        {categories?.length
          ? categories.map((cat, index, arr) => (
            <DesktopCategory
              key={index}
              category={cat}
              totalNumber={arr.length}
            />
          ))
          : null}
      </div>
      {categories.length > 8 ? (
        <ChevronRightIcon fill="black" className={styles.scrollIndicator} />
      ) : null}
    </div>
  );
};

export default DesktopCategories;

/**
 * {
    "__typename": "ProductCategoryResponse",
    "sequenceNo": 1,
    "searchTerms": [
        "Beverages"
    ],
    "categoryName": "Beverages",
    "icon": "https://storage.googleapis.com/alerzo-store-images-dev/category_icons/drinks.svg"
}
 */
