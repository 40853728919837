/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { DropdownCaret } from '@/assets/icons/shared';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import StyledLabel from '../Label';
import styles from './style.module.css';

const SelectWithRadio = ({
  values,
  placeholder,
  getValue,
  getLabel,
  onChange,
  label,
  required,
  value,
  symbol,
  background = '#ffffff',
  color = '#000000',
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [filteredVals, setFilteredVals] = useState(values);

  const handleValueClicked = (val) => {
    setCurrentValue(getLabel(val));
    onChange(val);
    setShowDropdown(false);
  };

  useEffect(() => {
    const actual = values.find((v) => getValue(v) === value);
    if (actual) {
      setCurrentValue(getLabel(actual));
    }
  }, [value]);

  useEffect(() => {
    setFilteredVals(values);

    const actual = values.find((v) => getValue(v) === value);

    if (actual) {
      setCurrentValue(getLabel(actual));
    } else {
      setCurrentValue('');
    }
  }, [values]);

  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div
        className={styles.container}
        style={{
          background,
        }}
      >
        {label && (
          <StyledLabel label={label} required={required} htmlFor="select" />
        )}
        <div
          className={
            value === placeholder
              ? classNames(styles.currentValue, styles.placeholder)
              : styles.currentValue
          }
          style={{
            color,
          }}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <span>
            {symbol}
            {value}
          </span>

          <DropdownCaret
            color={showDropdown ? '#00559E' : '#757575'}
            className={showDropdown ? styles.activeIcon : null}
          />
        </div>

        {/* Dropdown */}
        {showDropdown ? (
          <div className={styles.dropdownContainer}>
            <div className={styles.values}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  {filteredVals?.length
                    ? filteredVals.map((val) => {
                      const isActive = getLabel(val) === currentValue;

                      return (
                        <FormControlLabel
                          className={styles.normalValue}
                          value={getValue(val)}
                          control={(
                            <Radio
                              checked={isActive}
                              onChange={() => handleValueClicked(val)}
                            />
                            )}
                          label={getLabel(val)}
                        />
                      );
                    })
                    : null}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default SelectWithRadio;
