/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { DropdownCaret } from '@/assets/icons/shared';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SearchField from './SearchField';
import StyledLabel from '../Label';
import styles from './style.module.css';

const SearchableDropdown = ({
  values,
  defaultValue,
  placeholder = 'Search',
  getValue,
  getLabel,
  onChange,
  label,
  required,
  value,
}) => {
  const [searchTxt, setSearchTxt] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const [filteredVals, setFilteredVals] = useState(values);

  const handleValueClicked = (val) => {
    setCurrentValue(getLabel(val));
    onChange(val);
    setShowDropdown(false);
  };

  const valToShow = currentValue || defaultValue || placeholder;

  useEffect(() => {
    // eslint-disable-next-line max-len
    const newVals = values.filter((v) => getLabel(v).toLowerCase().includes(searchTxt.toLowerCase()));
    setFilteredVals(newVals);
  }, [searchTxt]);

  useEffect(() => {
    const actual = values.find((v) => getValue(v) === value);
    if (actual) {
      setCurrentValue(getLabel(actual));
    }
  }, [value]);

  useEffect(() => {
    setFilteredVals(values);
    setSearchTxt('');

    const actual = values.find((v) => getValue(v) === value);
    if (actual) {
      setCurrentValue(getLabel(actual));
    } else {
      setCurrentValue('');
    }
  }, [values]);

  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <div className={styles.container}>
        {label && (
          <StyledLabel label={label} required={required} htmlFor="select" />
        )}
        <div
          className={
            valToShow === placeholder
              ? classNames(styles.currentValue, styles.placeholder)
              : styles.currentValue
          }
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {valToShow}
          <DropdownCaret
            color={showDropdown ? '#00559E' : '#757575'}
            className={showDropdown ? styles.activeIcon : null}
          />
        </div>

        {/* Dropdown */}
        {showDropdown ? (
          <div className={styles.dropdownContainer}>
            <div style={{ position: 'relative' }}>
              <SearchField searchTxt={searchTxt} setSearchTxt={setSearchTxt} />
            </div>
            <div className={styles.values}>
              {filteredVals?.length
                ? filteredVals.map((val) => {
                  const isActive = getLabel(val) === currentValue;

                  return (
                    <div
                      className={
                          isActive ? styles.selectedValue : styles.normalValue
                        }
                      onClick={() => handleValueClicked(val)}
                    >
                      {getLabel(val)}
                    </div>
                  );
                })
                : null}
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default SearchableDropdown;
