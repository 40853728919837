/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';

// Core Packages
import Link from 'next/link';
import { AlerzoShopIcon, SKExpressIcon } from '@/assets/icons/desktop';
import { useRouter } from 'next/router';

// graphql
import { AuthUserContext } from '@/contexts/authUser';
import { CartContext } from '@/contexts/cart';
import { GqlContext } from '@/contexts/gql';
import { NotificationContext } from '@/contexts/Notification';
import { GET_DEFAULT_WAREHOUSE } from '@/graphql/cart';
import { GET_STATES_CITIES } from '@/graphql/home';
import DesktopSignin from './DesktopSignin';
import DesktopCart from './DesktopCart';
import DesktopSearch from './DesktopSearch';
import DesktopCitySelector from './DesktopCitySelector';
import DesktopCategories from './DesktopCategories';

// Styles
import styles from './style.module.css';

export default function DesktopHeader() {
  const router = useRouter();
  const miniHeaderRoutes = ['orders', 'account/edit', 'login'];
  const showCategories = !miniHeaderRoutes.some((str) => router.pathname.includes(str));
  const [citySelectorProps, setCitySelectorProps] = useState({});
  // const [loaded, setLoaded] = useState(false);
  const { data: stateCitiesData } = useQuery(GET_STATES_CITIES);
  const [getDefaultWarehouse, { data: defaultWarehouse }] = useLazyQuery(
    GET_DEFAULT_WAREHOUSE,
  );
  // const redirectToHome = () => {
  //   router.push('/');
  // };
  const { authUser = {} } = useContext(AuthUserContext);
  const { site } = useContext(GqlContext);

  const {
    cartItems,
    getCartItems,
    updateProductQuantity,
    removeItemFromCart,
    getTotalPriceOfCart,
    getGrossTotalOfCart,
    getItemQuantityForBundle,
    isAtleastOneProductAvailable,
  } = useContext(CartContext);

  const { showMiniNotification } = useContext(NotificationContext);

  const isLoggedIn = !!authUser.customerId;

  useEffect(async () => {
    const cookies = document.cookie?.split('; ')?.reduce((prev, current) => {
      const [name, ...value] = current.split('=');
      // eslint-disable-next-line no-param-reassign
      prev[name] = value.join('=');
      return prev;
    }, {});

    const stateAndCities = stateCitiesData?.getStateAndCities || [];
    let cookiesAlreadyExist = true;
    let cookieCityId = cookies?.cityId || '';
    let cookieCityName = cookies?.cityName || '';
    let cookieStateId = cookies?.stateId || '';
    let cookieStateName = cookies?.stateName || '';
    let cookieLandmarkId = cookies?.landmarkId || '';
    let cookieLandmark = cookies?.landmark || '';

    if (
      defaultWarehouse?.getDefaultWarehouse?.cityId
      && (!cookies
        || !cookieCityId
        || !cookieCityName
        || !cookieStateId
        || !cookieStateName
        || !cookieLandmarkId
        || !cookieLandmark)
    ) {
      cookiesAlreadyExist = false;
      cookieCityId = defaultWarehouse?.getDefaultWarehouse?.cityId;
      cookieCityName = defaultWarehouse?.getDefaultWarehouse?.cityName;
      cookieStateId = defaultWarehouse?.getDefaultWarehouse?.stateId;
      cookieStateName = defaultWarehouse?.getDefaultWarehouse?.stateName;
      cookieLandmarkId = defaultWarehouse?.getDefaultWarehouse?.landmarks?.[0]?.landmarkId;
      cookieLandmark = defaultWarehouse?.getDefaultWarehouse?.landmarks?.[0]?.landmarkName;
    }

    setCitySelectorProps({
      stateAndCities,
      cityId: cookieCityId,
      cityName: cookieCityName,
      stateId: cookieStateId,
      stateName: cookieStateName,
      landmarkId: cookieLandmarkId,
      landmark: cookieLandmark,
      cookiesAlreadyExist,
    });
    // if (loaded === true) {
    //   setLoaded(false);
    //   // redirectToHome();
    //   router.reload(window.location.href);
    // }
  }, [defaultWarehouse, stateCitiesData]);

  useEffect(() => {
    const cookies = document.cookie?.split('; ')?.reduce((prev, current) => {
      const [name, ...value] = current.split('=');
      // eslint-disable-next-line no-param-reassign
      prev[name] = value.join('=');
      return prev;
    }, {});
    const cookieCityId = cookies?.cityId || '';
    const cookieCityName = cookies?.cityName || '';
    const cookieStateId = cookies?.stateId || '';
    const cookieStateName = cookies?.stateName || '';
    const cookieLandmarkId = cookies?.landmarkId || '';
    const cookieLandmark = cookies?.landmark || '';
    if (
      site?.isAlerzo
      && (!cookies
        || !cookieCityId
        || !cookieCityName
        || !cookieStateId
        || !cookieStateName
        || !cookieLandmarkId
        || !cookieLandmark)
    ) {
      console.log('defaut warehouse called from desktop header');
      getDefaultWarehouse();
    }
  }, []);

  return (
    <>
      <div className={styles.container}>
        {!site?.isVeedez && (
          <Link href="/">
            <div className={styles.alerzoLogo}>
              {site?.isSK ? (
                <SKExpressIcon />
              ) : site?.isAlerzo ? (
                <AlerzoShopIcon />
              ) : null}
            </div>
          </Link>
        )}
        <DesktopCitySelector
          {...citySelectorProps}
          //  setLoaded={setLoaded}
        />

        <DesktopSearch />

        <div className={styles.headerEnd}>
          <DesktopSignin />

          <DesktopCart
            isLoggedIn={isLoggedIn}
            cartItems={cartItems}
            getCartItems={getCartItems}
            updateProductQuantity={updateProductQuantity}
            removeItemFromCart={removeItemFromCart}
            getTotalPriceOfCart={getTotalPriceOfCart}
            getGrossTotalOfCart={getGrossTotalOfCart}
            isAtleastOneProductAvailable={isAtleastOneProductAvailable}
            stateAndCities={citySelectorProps?.stateAndCities}
            cityId={citySelectorProps?.cityId}
            cityName={citySelectorProps?.cityName}
            stateId={citySelectorProps?.stateId}
            stateName={citySelectorProps?.stateName}
            getItemQuantityForBundle={getItemQuantityForBundle}
            showMiniNotification={showMiniNotification}
          />
        </div>
      </div>
      {showCategories ? (
        <div className={styles.categoryContainer}>
          <DesktopCategories />
        </div>
      ) : null}
    </>
  );
}
