import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import Link from 'next/link';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useRouter } from 'next/router';
// import ClickAwayListener from '@/components/ClickAwayListener';
import { GqlContext } from '@/contexts/gql';
import { GET_PRODUCT_FILTERS } from '@/graphql/products';
import styles from './style.module.css';

const AllCategoryDropdown = ({ content }) => {
  const router = useRouter();
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const { site } = useContext(GqlContext);

  const toggleVisibility = (bool) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    setCategoryOpen(bool);

  // eslint-disable-next-line max-len
  const [getSearchResults, { data: getProductFiltersApp }] = useLazyQuery(GET_PRODUCT_FILTERS);
  const filters = {
    Categories: [],
    SubCategories: [],
    brands: [],
    discounts: [],
  };

  useEffect(() => {
    const categories = filters.Categories.filter((c) => !!c);
    getSearchResults({
      variables: {
        categories,
        subCategories: filters.SubCategories,
        brands: filters.brands,
      },
    });
  }, []);

  useEffect(() => {
    if (!getProductFiltersApp) return;
    const {
      getProductFiltersApp: { brands, subCategories, categories },
    } = getProductFiltersApp;
    setCategoryList(categories);
    setBrandList(brands);
    setSubCatList(subCategories);
  }, [getProductFiltersApp]);

  return (
    <div
      className={styles.altContainer}
      style={categoryOpen ? { background: '#ffffff' } : {}}
      onMouseEnter={() => toggleVisibility(true)}
      onMouseLeave={() => toggleVisibility(false)}
      role="button"
      tabIndex={0}
    >
      <div
        className={styles.categoryTitle}
        role="button"
        tabIndex={0}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        onKeyDown={() => router.push('/category/none?sort=newarrival')}
        onClick={() => router.push('/category/none?sort=newarrival')}
      >
        {content}
      </div>
      {categoryOpen ? (
        <ClickAwayListener onClickAway={() => toggleVisibility(false)}>
          <div className={styles.altDropdown} style={{ left: '0px' }}>
            <div
              className={styles.altCategoryListing}
              onClick={() => toggleVisibility(false)}
              onKeyDown={() => toggleVisibility(false)}
              role="button"
              tabIndex={0}
            >
              <div className={styles.altCategoryListingContainer}>
                <h3>CATEGORY</h3>
                <div className={styles.listing}>
                  {categoryList.map((cat) => (
                    <Link
                      key={cat?.key}
                      href={`/category/${cat?.key}?cats=${cat.key}&sort=newarrival`}
                      // href="/category/[category]?sort=newarrival"
                    >
                      <p className={styles.listingItem} key={cat?.key}>
                        {cat?.key}
                      </p>
                    </Link>
                  ))}
                </div>
              </div>

              <div className={styles.altCategoryListingContainer}>
                <h3>SUB CATEGORY</h3>
                <div className={styles.listing}>
                  {subCatList.map((subCat) => (
                    <Link
                      key={subCat?.key}
                      // as={`/category/none?subCat=${subCat?.key}&sort=newarrival`}
                      href={`/category/none?subCat=${subCat?.key}&sort=newarrival`}
                    >
                      <p className={styles.listingItem} key={subCat?.key}>
                        {subCat?.key}
                      </p>
                    </Link>
                  ))}
                </div>
              </div>

              {site?.isSK ? null : (
                <div className={styles.altCategoryListingContainer}>
                  <h3>TOP BRANDS</h3>
                  <div className={styles.listing}>
                    {brandList.map((brand) => (
                      <Link
                        key={brand?.key}
                        href={`/category/none?brand=${brand?.key}&sort=newarrival`}
                        // href="/category/[category]?sort=newarrival"
                      >
                        <p className={styles.listingItem} key={brand?.key}>
                          {brand?.key}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  );
};

export default AllCategoryDropdown;
