import React, { useState, useEffect, useContext } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { SearchIcon } from '@/assets/icons/register';
import { GqlContext } from '@/contexts/gql';
import { setRecentSearches } from '@/utils/helper';
import { RECENT_SEARCH_NAMES } from '@/constants/index';

// Utils
// import { useTranslation } from "react-i18next";

// Styles
import styles from './style.module.css';

const PlacesSearch = ({ onSave }) => {
  // const { t: translate } = useTranslation();
  const { country } = useContext(GqlContext);
  const [value, setValue] = useState(null);
  const [mapsKey, setMapsKey] = useState('');
  // const googleMapsKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
  const googleMapsKey = 'AIzaSyBA9xrWrWUAphGT81u1ftmXrZYG6TCuf6E';
  const [reRenderKey, setReRenderKey] = useState('123');

  const handleVal = (val) => {
    const address = val.value.description;

    setValue(val);

    geocodeByAddress(address)
      .then((results) => {
        const final = getLatLng(results[0])
          .then((res) => res)
          .catch((err) => {
            console.error('Error while getting coordinates', err);
            throw err;
          });
        return final;
      })
      .then((res) => {
        const { lat, lng } = res;
        const loc = {
          lat,
          long: lng,
          address,
          location: address,
        };
        setRecentSearches(RECENT_SEARCH_NAMES.locations, loc);
        onSave(lat, lng, address);
      })
      .catch((err) => console.error('Error while geocoding', err));
  };

  useEffect(() => {
    if (googleMapsKey) {
      setMapsKey(googleMapsKey);
    }
  }, [googleMapsKey]);

  useEffect(() => {
    setReRenderKey(Math.random());
  }, [country?.countryCode]);

  return (
    <div key={reRenderKey}>
      {mapsKey ? (
        <div className={styles.container}>
          <span className={styles.icon}>
            <SearchIcon />
          </span>
          <GooglePlacesAutocomplete
            apiKey={mapsKey}
            selectProps={{
              value,
              onChange: handleVal,
              placeholder: 'Search for your store location',
              styles: {
                control: () => ({
                  border: 'none',
                }),
              },
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: [country?.countryCode?.toLowerCase() || 'ng'],
              },
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PlacesSearch;
