/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, useRef, useContext, useMemo,
} from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

// Functionality
import { useLazyQuery } from '@apollo/client';
import { SEARCH_PRODUCTS } from '@/graphql/products';
import {
  FETCH_ALERZO_CUSTOMER_POPULAR_SEARCHES,
  FETCH_ALERZO_CUSTOMER_RECENT_SEARCHES,
} from '@/graphql/home';
// import WarehouseLoader from '@/components/FilterComponents/warehouseLoader';
import { AuthUserContext } from '@/contexts/authUser';
import { GqlContext } from '@/contexts/gql';

// Custom
import { SearchIcon } from '@/assets/icons/desktop';
import Button from '@material-ui/core/Button';
import RestoreIcon from '@material-ui/icons/Restore';
import Chip from '@material-ui/core/Chip';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TrendingItem from '@/components/TrendingItem';

// Styles
import classnames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';
// import ClickAwayListener from '@/components/ClickAwayListener';
import { getCookie } from '@/utils/helper';
import styles from './style.module.css';

const Header = ({
  className, heading, onClick, actionText,
}) => {
  const containerClasses = classnames(
    'row j-space-between a-center',
    className,
  );
  return (
    <div className={containerClasses}>
      <span className={styles.heading}>{heading}</span>
      {actionText && (
        <Button className={styles.actionButton} onClick={onClick}>
          {actionText}
        </Button>
      )}
    </div>
  );
};

const DesktopSearch = () => {
  const router = useRouter();
  const { site } = useContext(GqlContext);
  const { authUser, warehouseId } = useContext(AuthUserContext);
  const [getSearchResults, { data }] = useLazyQuery(SEARCH_PRODUCTS);
  const [getPopularSearches, { data: popularSearchesData }] = useLazyQuery(
    FETCH_ALERZO_CUSTOMER_POPULAR_SEARCHES,
  );
  const [getRecentSearches, { data: recentSearchesData }] = useLazyQuery(
    FETCH_ALERZO_CUSTOMER_RECENT_SEARCHES,
    { fetchPolicy: 'network-only' },
  );
  const anchorRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');

  // const [warehouseId, setWarehouseId] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [variant, setVariant] = useState('');
  const [expandPopularSearches, setExpandPopularSearches] = useState(false);
  const [expandRecentSearches, setExpandRecentSearches] = useState(false);

  const startSearch = () => {
    if (!searchQuery) return;
    setShowDropdown(false);
    const toUse = variant || searchQuery;
    const source = variant.length > 0 ? 'variant' : 'searchQuery';
    const searchUri = encodeURI(
      `/search/${toUse}?search=${toUse}&sort=newarrival&&outOfStock=true&source=${source}`,
    );
    router.push(searchUri);
  };
  const inputClasses = classnames(styles.input, 'no-border');

  const handleFocus = () => {
    setShowDropdown(true);
    if (authUser?.customerId) {
      getRecentSearches({
        variables: {
          customerId: authUser?.customerId,
        },
      });
      getPopularSearches();
    }
  };

  const handleUnfocus = () => {
    setShowDropdown(false);
  };

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      startSearch();
    }
  };

  const startSuggestionSearch = () => {
    const cityId = getCookie('cityId');
    getSearchResults({
      variables: {
        searchQuery,
        sortBy: 'relevance',
        platform: 'ALERZOSHOP_PWA',
        warehouseId,
        cityId,
        ...(authUser?.verticals?.length
          ? {
            filters: {
              vertical: authUser.verticals,
            },
          }
          : {}),
      },
    });
  };

  const handleProductSelected = (prod) => {
    setVariant(prod?.variant);
    handleUnfocus();
    setSearchQuery(prod?.variant);
  };

  useEffect(() => {
    startSearch();
  }, [variant]);

  useEffect(() => {
    if (searchQuery.length >= 2) {
      startSuggestionSearch();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (authUser?.customerId) {
      getRecentSearches({
        variables: {
          customerId: authUser?.customerId,
        },
      });
      getPopularSearches();
    }
  }, [authUser]);

  // Styles
  const chipContainerClasses = classnames(styles.chipContainer, 'row wrap');

  const popularSearches = useMemo(() => {
    const popularSearches = popularSearchesData?.fetchAlerzoCustomerPopularSearches || [];

    return popularSearches
      .filter((s) => {
        if (s.search_term) return s.search_term;
        return s.search_text;
      })
      .sort((b, a) => a.times_searched - b.times_searched);
  }, [popularSearchesData]);

  const recentSearches = useMemo(() => {
    const recentSearches = recentSearchesData?.fetchAlerzoCustomerRecentSearches || [];

    return recentSearches.filter((s) => s.searchTerm);
  }, [recentSearchesData]);

  const expandSection = (section) => {
    if (section === 'popular') {
      setExpandPopularSearches(!expandPopularSearches);
    } else {
      setExpandRecentSearches(!expandRecentSearches);
    }
  };

  return (
    // <ClickAwayListener onClickAway={handleUnfocus}>
    <ClickAwayListener onClickAway={handleUnfocus}>
      <div className={styles.container} ref={anchorRef}>
        {/* <WarehouseLoader authUser={authUser} setWarehouseId={setWarehouseId} /> */}
        <>
          <div className={styles.inputContainer}>
            <input
              onFocus={handleFocus}
              onKeyDown={handleKeydown}
              className={inputClasses}
              placeholder="Search products, brands and categories"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {/* </ClickAwayListener> */}
          </div>
          <div
            className={styles.searchIconContainer}
            aria-label="search"
            onClick={startSearch}
            onKeyDown={handleKeydown}
            role="button"
            tabIndex={0}
          >
            <SearchIcon className={styles.searchIcon} />
          </div>
          {showDropdown ? (
            <div className={styles.searchContainer}>
              {data?.getProducts?.nodes?.length && searchQuery?.length >= 2 ? (
                <>
                  {data?.getProducts?.nodes?.map((product) => (
                    <p
                      onClick={() => handleProductSelected(product)}
                      className={styles.variant}
                      key={product.variant}
                    >
                      {product.variant}
                    </p>
                  ))}
                </>
              ) : recentSearches?.length ? (
                <>
                  <Header
                    className={styles.headingContainer}
                    heading="Recently Searched"
                    actionText={
                      expandRecentSearches ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    }
                    onClick={() => expandSection('recent')}
                  />
                  <div className={chipContainerClasses}>
                    {recentSearches
                      .slice(0, expandRecentSearches ? 20 : 5)
                      .map(({ searchTerm }) => (
                        <Link
                          key={searchTerm}
                          href={`/search/${searchTerm}?search=${searchTerm}&source=variant`}
                        >
                          <Chip
                            className={styles.chip}
                            key={searchTerm}
                            label={searchTerm}
                            icon={<RestoreIcon className={styles.icon} />}
                            onClick={handleUnfocus}
                          />
                        </Link>
                      ))}
                  </div>
                </>
              ) : null}

              {popularSearches?.length ? (
                <>
                  <Header
                    className={styles.headingContainer}
                    heading={
                      site?.isSK
                        ? 'Trending on SK Express'
                        : site?.isAlerzo
                          ? 'Trending on Alerzoshop'
                          : null
                    }
                    actionText={
                      expandPopularSearches ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    }
                    onClick={() => expandSection('popular')}
                  />
                  <div className={chipContainerClasses}>
                    {popularSearches
                      .slice(0, expandPopularSearches ? 20 : 5)
                      .map((item) => (
                        <TrendingItem
                          item={
                            item?.search_text
                              ? item.search_text
                              : item.search_term
                          }
                          key={
                            item?.search_text
                              ? item.search_text
                              : item.search_term
                          }
                          onClick={handleUnfocus}
                          source="variant"
                        />
                      ))}
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </>
      </div>
    </ClickAwayListener>
  );
};

export default DesktopSearch;
