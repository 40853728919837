import classnames from 'classnames';

export default function HorizontalScroll({
  className,
  children,
}) {
  const containerClasses = classnames('row j-start a-stretch scroll-x w-100', className);

  return (
    <div className={containerClasses}>
      {children}
    </div>
  );
}
