// Core Packages
import { createElement, useState } from 'react';
import classnames from 'classnames';

// Custom Components
import { ProfileIcon } from '@/assets/icons/shared';

// Utils
import { getNameInitials, getRandomItemFromArray } from '@/utils/helper';

// Styles
import styles from './styles.module.css';

const colors = [
  '#E06055',
  '#96AFC1',
  '#688EC7',
  '#B696C1',
  '#C1A696',
  '#AED582',
  '#68C7BC',
  '#90A4AD',
  '#F06292',
];

export default function EntityImage({
  className,
  defaultImg = ProfileIcon,
  entity = {},
  useDefault,
}) {
  const { name, image } = entity;
  const imageUrl = (image || '').trim();
  const nameInitials = getNameInitials(name);
  const [color] = useState(getRandomItemFromArray(colors));

  const classes = classnames(
    imageUrl ? styles.entityImage : styles.entityInitials,
    className,
    !imageUrl && !name ? styles.border : '',
  );

  if ((!imageUrl && !name) || useDefault) {
    return createElement(defaultImg, {
      className: classes,
      'data-test': 'component-entity-image-icon',
    });
  }

  return imageUrl ? (
    <>
      <img className={classes} src={imageUrl} alt={name} />
    </>
  ) : (
    <span
      data-test="component-entity-image-initials"
      style={{ backgroundColor: color }}
      className={classes}
    >
      {nameInitials}
    </span>
  );
}
