import React, { useState, useEffect, useContext } from 'react';
import LocationPickerAlt from '@/containers/LocationPickerAlt';
import LoaderDialog from '@/components/LoaderDialog';
import { GET_NEAREST_WAREHOUSE } from '@/graphql/misc';
import { useLazyQuery } from '@apollo/client';
import { NotificationContext } from '@/contexts/Notification';
import styles from './style.module.css';

const CitySelectionSK = ({ setForm, loading }) => {
  const { showMiniNotification } = useContext(NotificationContext);
  // eslint-disable-next-line max-len
  const [getNearestWarehouses, { data, loading: nearestWarehouseLoading }] = useLazyQuery(GET_NEAREST_WAREHOUSE);

  const [regDetails, setRegDetails] = useState({
    lat: '',
    long: '',
    landmark: '',
    address: '',
  });
  const setLatLong = () => (lat, long, address) => {
    getNearestWarehouses({
      variables: {
        lat: Number(lat),
        long: Number(long),
      },
    });
    setRegDetails({
      ...regDetails,
      lat,
      long,
      address,
    });
  };

  useEffect(() => {
    if (nearestWarehouseLoading) return;
    if (data?.getNearestWarehouse?.length > 0) {
      const warehouse = data?.getNearestWarehouse[0];
      const payload = {
        cityId: warehouse.City.cityId,
        cityName: warehouse.City.cityName,
        stateId: warehouse.City.State.stateId,
        stateName: warehouse.City.State.stateName,
        // landmark,
        warehouse: warehouse.warehouseId,
        reload: true,
        lat: regDetails.lat,
        long: regDetails.long,
        address: regDetails.address,
      };

      setForm(payload);
    } else if (data?.getNearestWarehouse?.length === 0) {
      showMiniNotification({
        duration: 3000,
        text: 'Your location is outside of our service area!',
        isError: true,
      });
    }
  }, [data]);

  return (
    <form style={loading ? { position: 'relative' } : {}}>
      <LoaderDialog loading={loading} />
      <LocationPickerAlt
        citySelectorSK
        isSmallDevice={false}
        showBackButtonAction={false}
        hideHeaderAndFooter
        // onClose={()}
        onSave={setLatLong()}
        headerClassName={styles.pickerHeader}
        isPopup
      />
    </form>
  );
};

export default CitySelectionSK;
