export const LocationIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0002 6.66683C8.1585 6.66683 6.66683 8.1585 6.66683 10.0002C6.66683 11.8418 8.1585 13.3335 10.0002 13.3335C11.8418 13.3335 13.3335 11.8418 13.3335 10.0002C13.3335 8.1585 11.8418 6.66683 10.0002 6.66683ZM17.4502 9.16683C17.0668 5.69183 14.3085 2.9335 10.8335 2.55016V0.833496H9.16683V2.55016C5.69183 2.9335 2.9335 5.69183 2.55016 9.16683H0.833496V10.8335H2.55016C2.9335 14.3085 5.69183 17.0668 9.16683 17.4502V19.1668H10.8335V17.4502C14.3085 17.0668 17.0668 14.3085 17.4502 10.8335H19.1668V9.16683H17.4502ZM10.0002 15.8335C6.77516 15.8335 4.16683 13.2252 4.16683 10.0002C4.16683 6.77516 6.77516 4.16683 10.0002 4.16683C13.2252 4.16683 15.8335 6.77516 15.8335 10.0002C15.8335 13.2252 13.2252 15.8335 10.0002 15.8335Z"
      fill="#69778C"
    />
  </svg>
);

export const TimeIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9915 1.6665C5.3915 1.6665 1.6665 5.39984 1.6665 9.99984C1.6665 14.5998 5.3915 18.3332 9.9915 18.3332C14.5998 18.3332 18.3332 14.5998 18.3332 9.99984C18.3332 5.39984 14.5998 1.6665 9.9915 1.6665ZM9.99984 16.6665C6.3165 16.6665 3.33317 13.6832 3.33317 9.99984C3.33317 6.3165 6.3165 3.33317 9.99984 3.33317C13.6832 3.33317 16.6665 6.3165 16.6665 9.99984C16.6665 13.6832 13.6832 16.6665 9.99984 16.6665ZM9.8165 5.83317H9.7665C9.43317 5.83317 9.1665 6.09984 9.1665 6.43317V10.3665C9.1665 10.6582 9.3165 10.9332 9.57484 11.0832L13.0332 13.1582C13.3165 13.3248 13.6832 13.2415 13.8498 12.9582C14.0248 12.6748 13.9332 12.2998 13.6415 12.1332L10.4165 10.2165V6.43317C10.4165 6.09984 10.1498 5.83317 9.8165 5.83317Z"
      fill="#69778C"
    />
  </svg>
);

export const MapIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 17C3.33579 17 3 17.3358 3 17.75C3 18.1642 3.33579 18.5 3.75 18.5H16.25C16.6642 18.5 17 18.1642 17 17.75C17 17.3358 16.6642 17 16.25 17H3.75Z"
      fill="black"
    />
    <path
      d="M10 16.864C9.89228 16.7378 9.77155 16.5945 9.6403 16.4359C9.08781 15.7683 8.35241 14.8336 7.61855 13.7634C6.88322 12.691 6.15889 11.4963 5.62072 10.3078C5.07828 9.10994 4.75 7.97247 4.75 7C4.75 4.10421 7.10421 1.75 10 1.75C12.8958 1.75 15.25 4.10421 15.25 7C15.25 7.97247 14.9217 9.10994 14.3793 10.3078C13.8411 11.4963 13.1168 12.691 12.3815 13.7634C11.6476 14.8336 10.9122 15.7683 10.3597 16.4359C10.2285 16.5945 10.1077 16.7378 10 16.864Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M8 7C8 5.9 8.9 5 10 5C11.1 5 12 5.9 12 7C12 8.1 11.11 9 10 9C8.9 9 8 8.1 8 7Z"
      fill="#297AFC"
    />
  </svg>
);

export const SearchIcon = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0257 13.2345L17.5948 16.9025L16.4157 18.1146L12.8473 14.4457C11.5196 15.5398 9.86817 16.1349 8.1665 16.1324C4.0265 16.1324 0.666504 12.6785 0.666504 8.42288C0.666504 4.16723 4.0265 0.713379 8.1665 0.713379C12.3065 0.713379 15.6665 4.16723 15.6665 8.42288C15.6689 10.1721 15.09 11.8697 14.0257 13.2345ZM12.354 12.5989C13.4116 11.4809 14.0022 9.98228 13.9998 8.42288C13.9998 5.10951 11.389 2.4266 8.1665 2.4266C4.94317 2.4266 2.33317 5.10951 2.33317 8.42288C2.33317 11.7354 4.94317 14.4192 8.1665 14.4192C9.68353 14.4216 11.1414 13.8145 12.229 12.7273L12.354 12.5989Z"
      fill="#69778C"
    />
  </svg>
);

export const RightChevron = ({ className }) => (
  <svg
    className={className}
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.742163 0.591482C0.417163 0.916482 0.417163 1.44148 0.742163 1.76648L3.9755 4.99981L0.742163 8.23315C0.417163 8.55815 0.417163 9.08315 0.742163 9.40815C1.06716 9.73315 1.59216 9.73315 1.91716 9.40815L5.74216 5.58315C6.06716 5.25815 6.06716 4.73315 5.74216 4.40815L1.91716 0.583148C1.6005 0.266481 1.06716 0.266482 0.742163 0.591482Z"
      fill="#69778C"
    />
  </svg>
);
