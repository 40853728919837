/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';

// Core Packages
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { GqlContext } from '@/contexts/gql';

import Link from 'next/link';

import LanguageSelect from '@/components/LanguageSelect';

import {
  AlerzoShopIcon,
  CallIcon,
  MailIcon,
  WhatsappIcon,
  BankIcon,
  CashIcon,
  FacebookIcon,
  InstagramIcon,
  PosIcon,
  TwitterIcon,
  YoutubeIcon,
  SKFooterIcon,
} from '@/assets/icons/desktop';

// Styles
import styles from './style.module.css';
import ScrollToTop from './ScrollToTop';

export default function DesktopFooter() {
  const router = useRouter();
  const { t: translate } = useTranslation();
  const { site } = useContext(GqlContext);
  const miniFooterPages = [
    'cart',
    'orders',
    'account/edit',
    'contact',
    'fallback',
  ];
  const showMiniFooter = miniFooterPages.some((str) => router.pathname.includes(str));

  const thisYear = new Date().getFullYear();

  return !site?.isVeedez ? (
    showMiniFooter ? (
      <div className={styles.miniFooter}>
        {site.isSK ? (
          <p>
            Copyright&copy; SK Supplies
            {` ${thisYear}`}
            . All Rights Reserved
          </p>
        ) : (
          <p>
            Copyright&copy; Alerzo Ltd
            {` ${thisYear}`}
            . All Rights Reserved
          </p>
        )}
        <p className={styles.miniFooterLinks}>
          <Link href="/contact">{translate('Contact Us')}</Link>
          {' | '}
          <Link href="/privacy">{translate('Privacy Policy')}</Link>
          {' | '}
          <Link href="/termsAndConditions">
            {translate('Terms & Conditions')}
          </Link>
          {' | '}
          <Link href="/faq">{translate('FAQ')}</Link>
        </p>
      </div>
    ) : (
      <div className={styles.container}>
        <ScrollToTop />
        <div className={styles.topFooter}>
          <div className={styles.footerCol}>
            <div className={styles.footerRow}>
              <div className={styles.footerRowItem}>
                <Link href="/">
                  <div>
                    {site?.isSK ? (
                      <SKFooterIcon />
                    ) : site?.isAlerzo ? (
                      <AlerzoShopIcon />
                    ) : null}
                  </div>
                </Link>
              </div>
            </div>

            {site?.isSK ? (
              <>
                <h5>REGISTERED OFFICE ADDRESS</h5>
                <p>Gulu Highway, Bombo Road, Plot No. 842-11962, Uganda</p>

                <div style={{ display: 'flex' }}>
                  <a href="tel:+256800275276" className={styles.iconLinkTag}>
                    <div className={styles.iconTextLink}>
                      <CallIcon />
                      <p>+256 800 275 276</p>
                    </div>
                  </a>

                  <a
                    href="https://api.whatsapp.com/send?phone=+256741241817"
                    className={styles.iconLinkTag}
                  >
                    <div className={styles.iconTextLink}>
                      <WhatsappIcon />
                      <p>+256 741 241 817</p>
                    </div>
                  </a>
                </div>

                <a
                  href="mailto:customerservice@sksupplies.ug"
                  className={styles.iconLinkTag}
                >
                  <div className={styles.iconTextLink}>
                    <MailIcon />
                    <p>customerservice@sksupplies.ug</p>
                  </div>
                </a>
              </>
            ) : (
              <>
                <h5>REGISTERED OFFICE ADDRESS</h5>
                <p>Alerzo Place, Ring-Road, Challenge, Ibadan, Nigeria</p>

                <div style={{ display: 'flex' }}>
                  <a href="tel:+18000253796" className={styles.iconLinkTag}>
                    <div className={styles.iconTextLink}>
                      <CallIcon />
                      <p>0800 025 3796</p>
                    </div>
                  </a>

                  <a
                    href={`
                    https://api.whatsapp.com/send?phone=2347017140429&text=${encodeURIComponent(
                      'Hello Alerzo 👋🏻',
                    )}`}
                    className={styles.iconLinkTag}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className={styles.iconTextLink}>
                      <WhatsappIcon />
                      <p>0701 714 0429</p>
                    </div>
                  </a>
                </div>

                <a
                  href="mailto:retail.support@alerzo.com"
                  className={styles.iconLinkTag}
                >
                  <div className={styles.iconTextLink}>
                    <MailIcon />
                    <p>retail.support@alerzo.com</p>
                  </div>
                </a>
              </>
            )}
          </div>

          <div className={styles.footerMidCol}>
            <div className={styles.footerMiniCol}>
              <h5>{translate('About').toUpperCase()}</h5>
              <Link href="/contact">
                <p className={styles.showPointer}>{translate('Contact Us')}</p>
              </Link>
              <Link href="/about">
                <p className={styles.showPointer}>{translate('About Us')}</p>
              </Link>
            </div>

            <div className={styles.footerMiniCol}>
              <Link href="/contact">
                <h5>HELP</h5>
              </Link>
              <Link href="/faq">
                <p className={styles.showPointer}>{translate('FAQ')}</p>
              </Link>
            </div>

            <div className={styles.footerMiniCol}>
              <h5>POLICY</h5>
              <Link href="/termsAndConditions">
                <p className={styles.showPointer}>
                  {translate('Terms & Conditions')}
                </p>
              </Link>
              <Link href="/privacy">
                <p className={styles.showPointer}>
                  {translate('Privacy Policy')}
                </p>
              </Link>
            </div>
          </div>
          <div className={styles.footerMidCol}>
            <div>
              <h5>CONNECT WITH US</h5>
              <div className={styles.iconLinkContainer}>
                <a href="https://facebook.com/alerzoshop">
                  <FacebookIcon className={styles.iconLink} />
                </a>
                <a href="https://instagram.com/alerzoshop">
                  <InstagramIcon className={styles.iconLink} />
                </a>
                <a href="https://twitter.com/alerzoshop">
                  <TwitterIcon className={styles.iconLink} />
                </a>
                <a href="https://www.youtube.com/channel/UC-efFy2hFgkqie9mQn3xNHw">
                  <YoutubeIcon className={styles.iconLink} />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.footerCol}>
            {site?.isSK ? null : (
              <div className={styles.footerRowItem}>
                <LanguageSelect useDesktopVersion />
              </div>
            )}
          </div>
        </div>
        <div className={styles.bottomFooter}>
          {site.isSK ? (
            <p>
              &copy;
              {thisYear}
              {' '}
              All Rights Reserved. SK Supplies
            </p>
          ) : (
            <p>
              &copy;
              {thisYear}
              {' '}
              All Rights Reserved. Alerzo Ltd.
            </p>
          )}
          <div className={styles.weAccept}>
            <p className={styles.weAcceptTitle}>WE ACCEPT</p>
            <div className={styles.weAcceptButton}>
              <CashIcon className={styles.weAcceptButtonIcon} />
              <p>CASH</p>
            </div>
            <div className={styles.weAcceptButton}>
              <BankIcon className={styles.weAcceptButtonIcon} />
              <p>BANK TRANSFER</p>
            </div>
            <div className={styles.weAcceptButton}>
              <PosIcon className={styles.weAcceptButtonIcon} />
              <p>POS</p>
            </div>
          </div>
        </div>
      </div>
    )
  ) : null;
}
