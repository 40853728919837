/* eslint-disable no-nested-ternary */
// Core Packages
import { useState, useContext } from 'react';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import { Link } from '@material-ui/core';

// Custom Components
import StyledDialog, { dialogSizes } from '@/components/Dialog';
import { UnselectedAlerzoIcon } from '@/components/BottomNavigationBar/icons';
import StyledModal from '@/components/Modal';
import { SKNoImageIcon } from '@/assets/icons/cart';
import CompactProduct from '@/components/CompactProduct';
import HorizontalScroll from '@/components/HorizontalScroll';
import LoaderDialog from '@/components/LoaderDialog';
import OutOfStockButton from '@/components/OutOfStockButton';
import BackButton from '@/components/BackButtonNew';

// Utils
import { CartContext } from '@/contexts/cart';
import { useTranslation } from 'react-i18next';
import { GqlContext } from '@/contexts/gql';
import { AuthUserContext } from '@/contexts/authUser';
import { GET_SIMILAR_PRODUCTS } from '@/graphql/products';
import {
  capitalizeText,
  imageSrcProvider,
  seoFriendlyParam,
} from '@/utils/helper';

// Styles
import styles from './style.module.css';

const ViewSimilarItemsPopUp = ({ setOpen, useDesktopVersion, product }) => {
  const { updateProductQuantity, cartItems } = useContext(CartContext);
  const { t: translate } = useTranslation();
  const { warehouseId, authUser } = useContext(AuthUserContext);

  const { site } = useContext(GqlContext);

  const {
    variant, brand, image, images, subCategory, productMainId,
  } = product;
  const { data, loading } = useQuery(GET_SIMILAR_PRODUCTS, {
    variables: {
      warehouseId,
      productId: productMainId,
      ...(authUser?.verticals?.length ? { verticals: authUser.verticals } : {}),
    },
    fetchPolicy: 'network-only',
  });
  const similarProducts = data?.getSimilarProduct?.nodes ?? [];

  const refinedImageOriginalUrl = imageSrcProvider(image, images?.imageUrl);
  const [imageBroken, setImageBroken] = useState(false);
  const handleProductQuantity = (product, quantity, sellerId, sellerName) => {
    updateProductQuantity(product, quantity, sellerId, sellerName);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getProductInfoClasses = (...classes) => classnames(...classes);

  const { getTotalItemQuantityInCart } = useContext(CartContext);

  const scrollClasses = classnames('maskedOverflowX', styles.list);
  return (
    <div className={styles.resetCss}>
      {useDesktopVersion ? (
        <div className={styles.largeScreenOnly}>
          <StyledDialog
            onClose={handleClose}
            containerClassName={styles.dialogContentAlt}
            hideCloseButton
            dialogSize={dialogSizes.xs}
            paperClasses={styles.vsiPaper}
          >
            <div className="row w-100">
              <div className={styles.titleBox}>
                <Link
                  href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
                    product.category,
                  )}/${seoFriendlyParam(product.subCategory)}/${product._id}`}
                >
                  <div
                    className={getProductInfoClasses(
                      'row',
                      styles.imageSection,
                    )}
                  >
                    {refinedImageOriginalUrl && !imageBroken ? (
                      <img
                        className={styles.image}
                        src={refinedImageOriginalUrl}
                        alt={variant}
                        onError={() => {
                          setImageBroken(true);
                        }}
                        onErrorCapture={() => setImageBroken(true)}
                      />
                    ) : site?.isSK ? (
                      <div className={styles.skLogoContainer}>
                        <SKNoImageIcon className="row a-center j-center" />
                      </div>
                    ) : site?.isAlerzo ? (
                      <UnselectedAlerzoIcon
                        className={getProductInfoClasses(
                          styles.image,
                          styles.alerzoLogo,
                        )}
                      />
                    ) : (
                      <UnselectedAlerzoIcon
                        className={getProductInfoClasses(
                          styles.image,
                          styles.alerzoLogo,
                        )}
                      />
                    )}
                  </div>
                </Link>

                <div className={styles.infoSection}>
                  <div className={styles.title}>
                    <Link
                      href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
                        product.category,
                      )}/${seoFriendlyParam(product.subCategory)}/${
                        product._id
                      }`}
                    >
                      {variant}
                    </Link>
                  </div>
                  <div className={styles.info}>
                    {`${capitalizeText(
                      subCategory || '',
                    )} \u2022 ${capitalizeText(brand)}`}
                  </div>
                  {similarProducts.length > 0 ? (
                    <div className="row w-100 j-space-between">
                      <div className={styles.outOfStock}>Out of Stock</div>
                      <OutOfStockButton
                        product={product}
                        sellerId={[product.sellerId]}
                        small
                      />
                    </div>
                  ) : (
                    <div className={styles.outOfStock}>Out of Stock</div>
                  )}
                </div>
              </div>
              <BackButton
                className={styles.backBtn}
                onClick={handleClose}
                showCross
              />
            </div>

            <div className={styles.itemsList}>
              {loading && (
                <div className={styles.loader}>
                  <LoaderDialog loading />
                </div>
              )}
              {!loading && similarProducts.length > 0 && (
                <>
                  <div className={styles.similarItemsTitle}>
                    {translate('Similar Items')}
                  </div>
                  <HorizontalScroll className={scrollClasses}>
                    {similarProducts.map((product) => (
                      <CompactProduct
                        key={product._id}
                        className={styles.product}
                        product={product}
                        cartQuantity={getTotalItemQuantityInCart(product._id)}
                        onQuantityChange={handleProductQuantity}
                        cartItems={cartItems}
                      />
                    ))}
                  </HorizontalScroll>
                </>
              )}
              {!loading && similarProducts?.length === 0 && (
                <>
                  <div className="col a-center j-center m-auto">
                    <img
                      src="/images/noSearchResults.svg"
                      alt="No Similar Items"
                    />
                    <span className={styles.greeting}>
                      {translate('No Similar Items Found')}
                    </span>
                  </div>
                  <OutOfStockButton
                    product={product}
                    // sellerId={product?.sellers?.map((s) => s.sellerId)}
                    buttonClass={styles.notifyButtonAlt}
                    sellerId={[product.sellerId]}
                    textClass={styles.notifyTextAlt}
                    regular
                  />
                </>
              )}
            </div>
          </StyledDialog>
        </div>
      ) : (
        <div className={styles.smallScreenOnly}>
          <StyledModal open onClose={handleClose} paperClass={styles.vsiPaper}>
            <div className={styles.titleBox}>
              <Link
                href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
                  product.category,
                )}/${seoFriendlyParam(product.subCategory)}/${product._id}`}
              >
                <div
                  className={getProductInfoClasses('row', styles.imageSection)}
                >
                  {refinedImageOriginalUrl && !imageBroken ? (
                    <img
                      className={styles.image}
                      src={refinedImageOriginalUrl}
                      alt={variant}
                      onError={() => {
                        setImageBroken(true);
                      }}
                      onErrorCapture={() => setImageBroken(true)}
                    />
                  ) : site?.isSK ? (
                    <div className={styles.skLogoContainer}>
                      <SKNoImageIcon className="row a-center j-center" />
                    </div>
                  ) : site?.isAlerzo ? (
                    <UnselectedAlerzoIcon
                      className={getProductInfoClasses(
                        styles.image,
                        styles.alerzoLogo,
                      )}
                    />
                  ) : (
                    <UnselectedAlerzoIcon
                      className={getProductInfoClasses(
                        styles.image,
                        styles.alerzoLogo,
                      )}
                    />
                  )}
                </div>
              </Link>

              <div className={styles.infoSection}>
                <div className={styles.title}>
                  <Link
                    href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
                      product.category,
                    )}/${seoFriendlyParam(product.subCategory)}/${product._id}`}
                  >
                    {variant}
                  </Link>
                </div>
                <div className={styles.info}>
                  {`${capitalizeText(
                    subCategory || '',
                  )} \u2022 ${capitalizeText(brand)}`}
                </div>
                {similarProducts.length > 0 ? (
                  <div className="row w-100 j-space-between">
                    <div className={styles.outOfStock}>Out of Stock</div>
                    <OutOfStockButton
                      product={product}
                      sellerId={[product.sellerId]}
                      small
                    />
                  </div>
                ) : (
                  <div className={styles.outOfStock}>Out of Stock</div>
                )}
              </div>
            </div>

            <div className={styles.itemsList}>
              {loading && (
                <div className={styles.loader}>
                  <LoaderDialog loading />
                </div>
              )}
              {!loading && similarProducts.length > 0 && (
                <>
                  <div className={styles.similarItemsTitle}>
                    {translate('Similar Items')}
                  </div>
                  <HorizontalScroll className={scrollClasses}>
                    {similarProducts.map((product) => (
                      <CompactProduct
                        key={product._id}
                        className={styles.product}
                        product={product}
                        cartQuantity={getTotalItemQuantityInCart(product._id)}
                        onQuantityChange={handleProductQuantity}
                        cartItems={cartItems}
                      />
                    ))}
                  </HorizontalScroll>
                </>
              )}
              {!loading && similarProducts?.length === 0 && (
                <>
                  <div className="col a-center j-center m-auto">
                    <img
                      src="/images/noSearchResults.svg"
                      alt="No Similar Items"
                    />
                    <span className={styles.greeting}>
                      {translate('No Similar Items Found')}
                    </span>
                  </div>
                  <OutOfStockButton
                    product={product}
                    // sellerId={product?.sellers?.map((s) => s.sellerId)}
                    buttonClass={styles.notifyButtonAlt}
                    sellerId={[product.sellerId]}
                    textClass={styles.notifyTextAlt}
                    regular
                  />
                </>
              )}
            </div>
          </StyledModal>
        </div>
      )}
    </div>
  );
};

export default ViewSimilarItemsPopUp;
