// Core Packages
import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import GoogleMapReact from 'google-map-react';
import { GqlContext } from '@/contexts/gql';

const Marker = () => (
  <div style={{ position: 'relative' }}>
    <img alt="" style={{ position: 'absolute', left: '-10px', top: '-35px' }} src="/marker.svg" />
  </div>
);

export default function LandmarkMap({
  landmarks,
  onClickLandmark,
  landmarkId,
  mapCenter,
}) {
  const [defaultProps, setDefaultProps] = useState(null);
  const { site } = useContext(GqlContext);

  const landmarkMarker = useMemo(
    () => site?.isAlerzo && mapCenter && mapCenter.lat && mapCenter.lng,
    [site, mapCenter],
  );

  useEffect(() => {
    if (landmarkMarker) {
      setDefaultProps(mapCenter);
    }
  }, [landmarkMarker, mapCenter]);

  const handleApiLoaded = (map, maps) => {
    if (landmarks?.length) {
      // eslint-disable-next-line array-callback-return
      landmarks.map((landmarkItem) => {
        const { lat, long, landmarkName } = landmarkItem;
        const newMarker = new maps.Marker({
          position: {
            lat: lat || 0,
            lng: long || 0,
          },
          title: `${landmarkName} landmark`,
          draggable: false,
          icon: '/marker_alt.svg',
          optimized: false,
        });

        newMarker.addListener('click', () => {
          onClickLandmark(landmarkItem);
        });

        newMarker.setMap(map);
      });
    }
  };

  return (
    <>
      <div style={{ height: '300px', width: '100%' }}>
        {defaultProps && landmarks?.length ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
            }}
            defaultCenter={defaultProps}
            center={mapCenter}
            zoom={(landmarkMarker && landmarkId) ? 18 : 8}
            defaultZoom={10}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {
              landmarkMarker
              && <Marker lat={mapCenter.lat} lng={mapCenter.lng} text={landmarkId} />
            }
          </GoogleMapReact>
        ) : null}
      </div>
    </>
  );
}
