/* eslint-disable no-nested-ternary */
// Core Packages
import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import classnames from 'classnames';
import Link from 'next/link';

// Custom Components
import { UnselectedAlerzoIcon } from '@/components/BottomNavigationBar/icons';
import { SKNoImageIcon } from '@/assets/icons/cart';
import QuantityControl from '@/components/QuantityControl';
import { BundleIcon } from '@/assets/icons/home';
import OutOfStockButton from '@/components/OutOfStockButton';
// import Banner from '@/components/Banner';
// import SellersPopupButton from '@/containers/SellersPopup/SellersPopupButton';
import ViewSimilarItemsButton from '@/containers/ViewSimilarItemsPopUp/ViewSimilarItemsButton';
// translate
import { useTranslation } from 'react-i18next';

// Utils
import {
  formatAmount,
  capitalizeText,
  calcPromotionPrice,
  imageSrcProvider,
  seoFriendlyParam,
} from '@/utils/helper';
import { CartContext } from '@/contexts/cart';
import { GqlContext } from '@/contexts/gql';

// Styles
import { WholesaleActiveIcon } from '@/assets/icons/bundle';
import styles from './style.module.css';
/* eslint-disable camelcase */
export default function CartItem({
  product,
  // removeItemFromCart,
  isEditing,
  getItemQuantityForBundle,
  cartItems,
  showBundle,
  showMiniNotification,
  sellerDetails,
  missingItemHint = false,
}) {
  const {
    getProductMaxQty,
    getProductPrice,
    updateProductQuantity,
    doesProductHaveAnyStock,
  } = useContext(CartContext);
  const { site, country } = useContext(GqlContext);
  const {
    // _id,
    cartQuantity = 0,
    subCategory,
    brand,
    image,
    images,
    variant,
    bundle,
    stockValue,
    sellerId,
  } = product;
  // const sellersStock = product.sellers.reduce((a, b) => a + b.stockValue, 0);
  const isThereAnyStock = doesProductHaveAnyStock(product);
  const outofStock = !isThereAnyStock;
  const { t: translate } = useTranslation();
  const refinedImageOriginalUrl = imageSrcProvider(image, images?.imageUrl);
  const rowClasses = classnames('col', styles.itemRow, {
    [styles.blur]: outofStock,
  });
  // const [sellerName, setSellerName] = useState('');
  const imageClasses = classnames('row j-center a-center', styles.itemImage);
  const infoClasses = classnames('col w-100', styles.itemInfo);
  const controlClasses = classnames(
    'row flex-1 j-end a-center ',
    styles.itemControl,
  );

  const [activateWholeSale, setActivateWholesale] = useState(false);
  const [activateBundleWholesale, setActivateBundleWholesale] = useState(false);
  const [retailPrice, setRetailPrice] = useState(0);
  const [retailPriceWithoutPromotion, setRetailPriceWithoutPromotion] = useState(0);
  const [wholesalePrice, setWholesalePrice] = useState(0);
  const [maxQty, setMaxQty] = useState(10);
  const [bundleMinQty, setBundleMinQty] = useState(undefined);
  const [imageBroken, setImageBroken] = useState(false);
  const [minQtyForDiscount, setMinQtyForDiscount] = useState(undefined);
  const [mandatoryFactor, setMandatoryFactor] = useState(undefined);
  // const [mobilePopupVisible, setMobilePopupVisible] = useState(false);
  // const [desktopPopupVisible, setDesktopPopupVisible] = useState(false);
  const [mobileVSPopupVisible, setMobileVSPopupVisible] = useState(false);
  const [desktopVSPopupVisible, setDesktopVSPopupVisible] = useState(false);

  const updateQuantity = (value) => {
    updateProductQuantity(
      product,
      value,
      product.sellerId || sellerDetails?.sellerId,
      product.sellerName || sellerDetails?.sellerName,
    );
  };
  const removeItem = () => {
    updateProductQuantity(
      product,
      0,
      product.sellerId || sellerDetails?.sellerId,
      product.sellerName || sellerDetails?.sellerName,
    );
  };

  const getClassNames = () => {
    let result;
    if (activateWholeSale) {
      result = classnames(styles.wholeSalePriceActive);
    } else {
      result = classnames(styles.wholeSalePriceInactive);
    }

    return result;
  };

  const refreshDiscount = () => {
    const { wholesaleActive, minDiscountQty } = getProductPrice(product);
    setMinQtyForDiscount(minDiscountQty);

    setActivateWholesale(wholesaleActive);
  };

  const handleBundleClicked = () => {
    if (!showBundle || !bundle) return;
    showBundle(bundle);
  };

  useEffect(() => {
    if (!bundle?._id) return;
    const bundleCartStatus = getItemQuantityForBundle(bundle._id);
    setBundleMinQty(bundleCartStatus?.minQuantity);
    setActivateBundleWholesale(bundleCartStatus?.activateWholesale);
  }, []);

  useEffect(() => {
    refreshDiscount();
    // Handle pricing

    const { retail, wholesale } = getProductPrice(product);
    const { retail: retailWithoutPromotion } = getProductPrice(
      product,
      null,
      null,
      true,
    );
    const newMax = getProductMaxQty(product);

    // Handle pricing
    setRetailPrice(retail);
    setWholesalePrice(wholesale);

    setRetailPriceWithoutPromotion(retailWithoutPromotion);

    // Handle quantity limits by getting smallest limiter value
    setMaxQty(newMax);
  }, [activateBundleWholesale, cartQuantity, product]);

  useEffect(() => {
    if (!bundle) return;
    const bundleCartStatus = getItemQuantityForBundle(bundle._id);
    setActivateBundleWholesale(bundleCartStatus?.activateWholesale);
    if (bundle.mandatory) {
      setMandatoryFactor(bundleCartStatus.mandatoryQuantifier);
    }
  }, [cartItems, bundle]);

  useEffect(() => {
    refreshDiscount();
  }, [bundleMinQty, wholesalePrice, product]);

  useEffect(() => {
    refreshDiscount();
  }, []);

  // useEffect(() => {
  //   if (product?.sellerName || sellerDetails?.sellerName) {
  //     setSellerName(product?.sellerName || sellerDetails?.sellerName);
  //   }
  // }, [sellerDetails]);
  const maxDiscountedQuantity = useMemo(
    () => (activateBundleWholesale
      ? Math.min(maxQty, stockValue, bundle?.maxDiscountedQty)
      : false),
    [bundle, maxQty, activateBundleWholesale],
  );

  const mandatoryQuantity = useMemo(() => {
    if (activateBundleWholesale && !!mandatoryFactor && !!bundle?.mandatory) {
      return Math.min(
        maxQty,
        stockValue,
        mandatoryFactor * bundle.maxDiscountedQty,
      );
    }
    return false;
  }, [activateBundleWholesale, bundle, mandatoryFactor, maxDiscountedQuantity]);

  const promotionalProduct = useMemo(() => {
    if (
      activateBundleWholesale
      && bundle?.bundleType === 'DISCOUNT'
      && bundle?.productType === 'PROMOTION'
    ) {
      const remainingQuantity = !bundle.mandatory
        ? maxDiscountedQuantity - cartQuantity
        : mandatoryQuantity - cartQuantity;
      return {
        ...bundle,
        remainingQuantity,
        promotedQuantity: !bundle.mandatory
          ? remainingQuantity <= 0
            ? maxDiscountedQuantity
            : cartQuantity
          : mandatoryQuantity,

        regularQuantity:
          remainingQuantity <= 0
            ? !bundle.mandatory
              ? cartQuantity - maxDiscountedQuantity
              : cartQuantity - mandatoryQuantity
            : 0,
      };
    }
    return false;
  }, [
    bundle,
    cartQuantity,
    activateBundleWholesale,
    mandatoryQuantity,
    maxDiscountedQuantity,
  ]);

  const bundleDiscountText = useMemo(() => {
    if (bundle) {
      if (bundle?.discount) {
        if (bundle?.discountType === 'ABSOLUTE') {
          return `${country?.currencySymbol} ${bundle.discount} OFF | Max Qty ${
            !bundle.mandatory
              ? maxDiscountedQuantity
              : mandatoryQuantity || bundle.maxDiscountedQty
          }`;
        }
        if (bundle?.discountType === 'PERCENTAGE') {
          return `${bundle.discount}% OFF | Max Qty ${
            !bundle.mandatory
              ? maxDiscountedQuantity
              : mandatoryQuantity || bundle.maxDiscountedQty
          }`;
        }
      } else {
        return `Max Qty ${
          !bundle?.mandatory
            ? maxDiscountedQuantity
            : mandatoryQuantity || bundle?.maxDiscountedQty
        }`;
      }
    }
    return '';
  }, [bundle, mandatoryQuantity, maxDiscountedQuantity]);

  const discountedPrice = useMemo(
    () => calcPromotionPrice(retailPriceWithoutPromotion, bundle),
    [bundle, retailPriceWithoutPromotion],
  );

  const cartQuantityWithPromotions = useMemo(() => {
    if (promotionalProduct) {
      if (promotionalProduct.remainingQuantity > 0) {
        return promotionalProduct.remainingQuantity;
      }
      return promotionalProduct.regularQuantity;
    }
    return cartQuantity;
  }, [cartQuantity, promotionalProduct]);

  const getProductInfoClasses = (...classes) => classnames(styles.item, ...classes);
  const secondPriceClasses = classnames(styles.priceInactive, styles.priceAlt);
  const promotionalProductClasses = classnames(
    'row j-space-between a-center',
    styles.promotionalPricing,
  );

  const getPromotionText = () => {
    let result;
    if (
      !!promotionalProduct?.promotedQuantity
      && promotionalProduct?.promotedQuantity < maxDiscountedQuantity
    ) {
      result = (
        <>
          <span>
            {`
           ${
             maxDiscountedQuantity - promotionalProduct?.promotedQuantity
           } ${translate('more')} `}
          </span>
          {translate('available on offer')}
        </>
      );
    } else if (!activateWholeSale) {
      result = (
        <>
          {translate('Add')}
          <span>
            {` ${minQtyForDiscount - cartQuantity} ${translate('more')} `}
          </span>
          {translate('for wholesale price')}
        </>
      );
    } else if (activateWholeSale) {
      result = (
        <>
          <WholesaleActiveIcon className={styles.wholesaleIcon} />
          <span>{translate('Wholesale price active')}</span>
        </>
      );
    }

    return result;
  };

  return (
    <div className={rowClasses}>
      <div>
        <div className="row a-stretch w-100">
          <Link
            href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
              product.category,
            )}/${seoFriendlyParam(product.subCategory)}/${
              product._id
            }?source=cart`}
          >
            <div className={imageClasses}>
              {refinedImageOriginalUrl && !imageBroken ? (
                <img
                  src={refinedImageOriginalUrl}
                  alt={variant}
                  // onError={() => setImageBroken(true)}
                  onErrorCapture={() => setImageBroken(true)}
                />
              ) : site?.isSK ? (
                <div className={styles.skLogoContainer}>
                  <SKNoImageIcon className={styles.skLogo} />
                </div>
              ) : site?.isAlerzo ? (
                <UnselectedAlerzoIcon className={styles.alerzoLogo} />
              ) : (
                <UnselectedAlerzoIcon className={styles.alerzoLogo} />
              )}
            </div>
          </Link>
          <div className="col w-100">
            <div className={infoClasses}>
              <div className="row j-space-between a-center">
                <p className={styles.itemVariant}>
                  <Link
                    href={`/${seoFriendlyParam(variant)}/${seoFriendlyParam(
                      product.category,
                    )}/${seoFriendlyParam(product.subCategory)}/${
                      product._id
                    }?source=cart`}
                  >
                    {variant}
                  </Link>
                </p>

                {bundle
                  && bundle.sellerId === sellerId
                  && bundle.productType === 'BUNDLE' && (
                    <div
                      onClick={handleBundleClicked}
                      onKeyDown={handleBundleClicked}
                      role="button"
                      tabIndex={0}
                      className={getProductInfoClasses(styles.bundleIcon)}
                    >
                      <div className={styles.bundleIconContainer}>
                        <BundleIcon />
                        <span>{translate('Bundle')}</span>
                      </div>
                    </div>
                )}
              </div>
              <p className={styles.itemCategory}>
                {`${capitalizeText(subCategory)} • ${capitalizeText(brand)}`}
              </p>
              {!outofStock && (
                <>
                  {promotionalProduct && (
                    <div className={promotionalProductClasses}>
                      <div className="row j-start ">
                        <div className="col j-start  ">
                          <div className={styles.price}>
                            {`${country?.currencySymbol} ${formatAmount(
                              discountedPrice,
                              true,
                            )}`}
                          </div>

                          {!!bundle.discount && (
                            <div className={secondPriceClasses}>
                              {`${country?.currencySymbol} ${formatAmount(
                                retailPriceWithoutPromotion,
                                true,
                              )}`}
                            </div>
                          )}
                        </div>
                        <div className={styles.discountMarker}>
                          {bundleDiscountText}
                        </div>
                      </div>

                      <div className="row j-end">
                        {!!promotionalProduct.promotedQuantity && (
                          <div className={styles.totalPrice}>
                            {`${country?.currencySymbol} ${formatAmount(
                              promotionalProduct.promotedQuantity
                                * discountedPrice,
                              true,
                            )}`}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(!promotionalProduct
                    || promotionalProduct.remainingQuantity <= 0) && (
                    <div
                      className={getProductInfoClasses(
                        'row a-center',
                        styles.pricing,
                      )}
                    >
                      <div className="col j-start  ">
                        {cartQuantityWithPromotions > 0 && (
                          <div
                            className={
                              activateWholeSale
                              && wholesalePrice
                              && wholesalePrice !== retailPrice
                                ? styles.priceInactive
                                : styles.price
                            }
                          >
                            {`${country?.currencySymbol}  ${formatAmount(
                              retailPrice,
                              true,
                            )}`}
                          </div>
                        )}
                        {minQtyForDiscount
                          && wholesalePrice
                          && wholesalePrice !== retailPrice
                          && cartQuantityWithPromotions > 0 && (
                            <div className={getProductInfoClasses(styles.info)}>
                              <span
                                className={
                                  !activateWholeSale
                                    ? styles.priceInactive
                                    : styles.price
                                }
                              >
                                {`${country?.currencySymbol}  ${formatAmount(
                                  wholesalePrice,
                                  true,
                                )} `}
                              </span>

                              {translate('(Min. {qty} Items)').replace(
                                '{qty}',
                                minQtyForDiscount,
                              )}
                              {cartQuantityWithPromotions > 0
                                && !activateWholeSale
                                && activateBundleWholesale
                                && bundle?.productType === 'PROMOTION' && (
                                  <span className={styles.regularPriceMarker}>
                                    Regular Price
                                  </span>
                              )}
                            </div>
                        )}
                      </div>
                      {cartQuantityWithPromotions > 0 && (
                        <p className={styles.itemTotal}>
                          {`${country?.currencySymbol} ${
                            activateWholeSale
                              ? formatAmount(
                                wholesalePrice * cartQuantityWithPromotions,
                                true,
                              )
                              : formatAmount(
                                retailPrice * cartQuantityWithPromotions,
                                true,
                              )
                          }`}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {outofStock && (
              <div className={getProductInfoClasses(styles.notInStock)}>
                {translate('Stock Unavailable')}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row a-center j-space-between w-100">
        {isEditing && !missingItemHint && !bundle?.mandatory ? (
          <button
            className={styles.itemDelete}
            type="button"
            onClick={removeItem}
          >
            {translate('Remove')}
          </button>
        ) : (
          bundle?.mandatory
          && (activateBundleWholesale ? (
            <span className={styles.promotedPriceMarker}>Mandatory</span>
          ) : (
            <button
              className={styles.itemDelete}
              type="button"
              onClick={removeItem}
            >
              {translate('Remove')}
            </button>
          ))
          // missingItemHint && (
          //   <div className={styles.banner}>
          //     <Banner />
          //   </div>
        )}
        {(!promotionalProduct || promotionalProduct.remainingQuantity <= 0)
          && (!outofStock
            ? minQtyForDiscount
              && cartQuantityWithPromotions > 0
              && retailPrice !== wholesalePrice
              && isEditing && (
                <div className={getClassNames()}>{getPromotionText()}</div>
            )
            : null)}
        <div className={controlClasses}>
          {isEditing && !outofStock ? (
            <QuantityControl
              className={styles.itemController}
              value={cartQuantity}
              max={maxQty}
              onChange={updateQuantity}
              useTypedInput={!cartQuantity}
              variant={variant}
              showMiniNotification={showMiniNotification}
              disableMinusButton={
                bundle?.mandatory ? cartQuantity === mandatoryQuantity : false
              }
            />
          ) : outofStock ? (
            <div className={styles.outofStockContainer}>
              <OutOfStockButton
                product={product}
                sellerId={[product.sellerId]}
              />
              <ViewSimilarItemsButton
                product={product}
                mobilePopupVisible={mobileVSPopupVisible}
                desktopPopupVisible={desktopVSPopupVisible}
                setMobilePopupVisible={setMobileVSPopupVisible}
                setDesktopPopupVisible={setDesktopVSPopupVisible}
              />
            </div>
          ) : (
            <span className={styles.itemQuantity}>
              {`${cartQuantity} ${translate('QTY')}`}
            </span>
          )}
        </div>
      </div>
      {/* <div className={styles.sellerControls}>
        {sellerName ? (
          <>
            <Banner text={sellerName} />
            {' '}
            {!missingItemHint && (
              <SellersPopupButton
                product={product}
                mobilePopupVisible={mobilePopupVisible}
                desktopPopupVisible={desktopPopupVisible}
                setMobilePopupVisible={setMobilePopupVisible}
                setDesktopPopupVisible={setDesktopPopupVisible}
                useTextButton
              />
            )}
          </>
        ) : null}
      </div> */}
    </div>
  );
}
