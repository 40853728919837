import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import { SearchIcon } from '@/assets/icons/register';
import styles from './style.module.css';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '100px',
      },
    },
  },
})(TextField);

const SearchField = ({ searchTxt, searchPlaceholder, setSearchTxt }) => (
  <div className={styles.searchContainer} key="searchFieldContainer">
    <CustomTextField
      onClick={(event) => {
        event.stopPropagation();
      }}
      autoFocus
      //   ref={inputFocus}
      key="searchField"
      value={searchTxt}
      onChange={(e) => setSearchTxt(e.target.value)}
      variant="outlined"
      placeholder={searchPlaceholder || ''}
      className={styles.searchInput}
      // style={{ border: '1px solid red' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  </div>
);

export default SearchField;
