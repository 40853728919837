import React from 'react';

// Material
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

// Custom
// import ClickAwayListener from '@/components/ClickAwayListener';

export default function DesktopDialog({
  className,
  children = null,
  open,
  setOpen,
  anchorRef,
  place,
}) {
  const toggleVisibility = () => setOpen((prevOpen) => !prevOpen);

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement={place}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={toggleVisibility}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                className={className}
              >
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
