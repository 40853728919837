import React, { useState, useContext, useEffect } from 'react';
import { NotifyIcon } from '@/assets/icons/shared';
import { REQUEST_NOTIFICATION } from '@/graphql/cart';
import { AuthUserContext } from '@/contexts/authUser';
import { CartContext } from '@/contexts/cart';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { getCookie } from '@/utils/helper';
import classNames from 'classnames';
import { notifyProduct } from '@/utils/analytics';
import styles from './style.module.css';

const RightTick = () => (
  <svg
    width="9"
    height="7"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: '3px' }}
  >
    <path
      d="M8.78344 1.33846L3.35702 6.76923C3.20373 6.92308 3.00446 7 2.82051 7C2.63657 7 2.42196 6.92308 2.284 6.76923L0.229933 4.72308C-0.0766444 4.41539 -0.0766444 3.93846 0.229933 3.63077C0.53651 3.32308 1.01171 3.32308 1.31828 3.63077L2.80518 5.12308L7.67976 0.230771C7.98634 -0.0769212 8.46154 -0.0769212 8.76811 0.230771C9.07469 0.538463 9.07469 1.03077 8.78344 1.33846Z"
      fill="#00954B"
    />
  </svg>
);

const OutOfStockButton = ({
  product,
  buttonClass,
  textClass,
  sellerId,
  regular,
  small,
}) => {
  const { authUser } = useContext(AuthUserContext);
  const { t: translate } = useTranslation();
  const { getProductNotification, productsPendingNotification, refetch } = useContext(CartContext);

  const [notifyCustomerDetails] = useMutation(REQUEST_NOTIFICATION);
  const [isRequested, setIsRequested] = useState(false);

  const handleRequest = async () => {
    setIsRequested(true);
    const warehouseId = getCookie('warehouse');

    if (!sellerId?.length) {
      console.error("No seller Id's found", sellerId);
      return;
    }
    if (authUser) {
      try {
        sellerId.forEach(async (id) => {
          await notifyCustomerDetails({
            variables: {
              customerId: authUser.customerId,
              warehouseId,
              productId: product._id,
              sellerId: id,
            },
          }).then(() => {
            refetch();
          });
        });
        notifyProduct(product._id, warehouseId, authUser.customerId);
      } catch (error) {
        console.error('Error while notifying customer', error);
      }
    }
  };

  useEffect(() => {
    const notificationPending = getProductNotification(product);
    setIsRequested(notificationPending);
  }, [productsPendingNotification]);

  const buttonClasses = classNames(styles.container, buttonClass);
  const textClasses = classNames('row a-center', styles.notifyText, textClass);
  // eslint-disable-next-line no-nested-ternary
  return isRequested ? (
    small ? (
      <span className={textClasses}>
        <RightTick />
        {translate('We will notify you')}
      </span>
    ) : (
      <span className={textClasses}>
        {translate('We will notify you when the item is available')}
      </span>
    )
  ) : (
    <button
      style={
        regular
          ? {
            background: '#e7eef6',
            padding: '10px 24px',
            fontSize: '13px',
          }
          : {}
      }
      className={buttonClasses}
      type="button"
      onClick={handleRequest}
    >
      <span className={styles.notifyIcon}>
        <NotifyIcon />
      </span>
      <p>{translate('Notify Me')}</p>
    </button>
  );
};

export default OutOfStockButton;
