// Core Packages
import React from 'react';
import StyledButton from '@/components/Button';
import { MapIcon } from '@/assets/icons/register';

// Utils
import { useTranslation } from 'react-i18next';

import styles from '../style.module.css';

export default function ConfirmationBox({
  location,
  address,
  Confirm,
  citySelectorSK,
}) {
  const { t: translate } = useTranslation();
  return (
    <div
      className={
        citySelectorSK ? styles.ConfirmationBox1 : styles.ConfirmationBox
      }
      // className={styles.ConfirmationBox}
    >
      <span className={styles.title}>{translate('Select Store Location')}</span>
      <div className={styles.storeNameContainer}>
        <MapIcon />
        <span className={styles.storeName}>
          {location || 'Drag the marker to select a location'}
        </span>
      </div>
      <span className={styles.address}>{address}</span>
      <StyledButton onClick={Confirm}>
        {translate('Confirm Location')}
      </StyledButton>
    </div>
  );
}
