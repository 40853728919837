// Core Packages
import React, { useState, useEffect, useContext } from 'react';
import GoogleMapReact from 'google-map-react';

// Material
import BackIcon from '@material-ui/icons/KeyboardBackspace';

// utils
import { setRecentSearches } from '@/utils/helper';
import { RECENT_SEARCH_NAMES } from '@/constants/index';
import { GqlContext } from '@/contexts/gql';
import ConfirmationBox from './ConfirmationBox';

// styles
import styles from '../style.module.css';
// import styleMap from './style.module.css';

export default function GetUserLocation({
  onBack,
  onSave,
  isSmallDevice,
  citySelectorSK,
  showBackButtonAction = true,
}) {
  // const [showconfirmationBox, setShowConfirmationBox] = useState(false);
  const { site } = useContext(GqlContext);
  const [defaultProps, setDefaultProps] = useState(null);
  const [latitude, setLatitude] = useState();
  const [longtiude, setLongtude] = useState();
  const [address, setAddress] = useState('');
  const [location, setLocation] = useState('');

  const confirmLocation = () => {
    // setShowConfirmationBox(false);
    if (address.length) {
      onSave(latitude, longtiude, address);
      const loc = {
        lat: latitude,
        long: longtiude,
        address,
        location,
      };
      setRecentSearches(RECENT_SEARCH_NAMES.locations, loc);
    }
  };

  useEffect(() => {
    function success(position) {
      const defaultProps = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      setDefaultProps(defaultProps);
    }

    function error() {
      if (site?.isAlerzo) setDefaultProps({ lat: 6.465422, lng: 3.406448 });
      else setDefaultProps({ lat: 0.3476, lng: 32.5825 });
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else if (site?.isAlerzo) {
      setDefaultProps({ lat: 6.465422, lng: 3.406448 });
    } else setDefaultProps({ lat: 0.3476, lng: 32.5825 });
  }, []);

  const handleApiLoaded = (map, maps) => {
    const clickedLocation = new maps.LatLng(defaultProps.lat, defaultProps.lng);
    const geocoder = new maps.Geocoder();

    const marker = new maps.Marker({
      position: clickedLocation,
      map,
      title: 'user location',
      draggable: true,
      icon: '/marker.svg',
    });

    maps.event.addListener(marker, 'dragend', () => {
      let lat;
      let lng;
      let address;
      let location;
      geocoder.geocode({ latLng: marker.getPosition() }, (results, status) => {
        if (status === maps.GeocoderStatus.OK) {
          lat = marker.getPosition().lat();
          lng = marker.getPosition().lng();
          address = results[0].formatted_address;

          location = address.split(',')[0].trim();

          setLatitude(lat);
          setLongtude(lng);
          setLocation(location);
          console.log('Iam loc', location);
          setAddress(address);
          // setShowConfirmationBox(true);
        }
      });
    });

    marker.setMap(map);
  };

  return (
    <>
      {!showBackButtonAction && (
      <div className={styles.backButton} onClick={onBack} aria-hidden="true">
        <BackIcon />
      </div>
      )}
      <div
        style={{
          height: isSmallDevice ? '70vh' : '50vh',
          width: '100%',
          minHeight: '50vh',
        }}
      >
        {defaultProps && (
          <>
            <div
              style={{
                height: isSmallDevice ? '70vh' : '50vh',
                width: '100%',
                minHeight: '50vh',
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
                }}
                defaultCenter={defaultProps}
                defaultZoom={10}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              />
            </div>
          </>
        )}
      </div>

      <ConfirmationBox
        latitude={latitude}
        longtiude={longtiude}
        location={location}
        address={address}
        Confirm={confirmLocation}
        citySelectorSK={citySelectorSK}
      />
    </>
  );
}
