/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useRef, useState, useContext, useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { DialogContent } from '@/containers/CitySelector';
import DesktopDialog from '@/components/DesktopDialog';
import { AuthUserContext } from '@/contexts/authUser';
import { LocationIcon, FocusArrow } from '@/assets/icons/desktop';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { decodeURIString, setCookie, getCookie } from '@/utils/helper';

// Utilities
import { useTranslation } from 'react-i18next';

// Styles
import classNames from 'classnames';
import styles from './style.module.css';

export default function DesktopHeader(props) {
  const router = useRouter();
  const { t: translate } = useTranslation();
  const { authUser } = useContext(AuthUserContext);

  const {
    stateAndCities,
    cityId,
    stateId,
    stateName,
    cityName,
    landmark,
    landmarkId,
    setLoaded,
  } = props;
  const anchorRef = useRef(null);
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [showFocus, setShowFocus] = useState(false);

  const toggleVisibility = () => setLocationDropdownOpen((prevOpen) => !prevOpen);

  const focusClasses = classNames(styles.focusArrow);

  useEffect(() => {
    if (router.pathname !== '/') return;
    // Only fire if user is not logged in
    if (authUser?.customerId) {
      if (showFocus) setShowFocus(false);
      return;
    }
    const focusSeen = !!getCookie('guidedFocus');
    if (focusSeen) return;
    setShowFocus(true);
    setCookie('guidedFocus', 'seen');
  }, [authUser]);
  return (
    <div className={styles.container}>
      <div className={styles.focusContainer}>
        {showFocus ? (
          <>
            <div
              className={styles.focus}
              tabIndex={0}
              onKeyDown={() => setShowFocus(false)}
              role="button"
              onClick={() => setShowFocus(false)}
            />
            <div
              className={focusClasses}
              tabIndex={0}
              onKeyDown={() => setShowFocus(false)}
              role="button"
              onClick={() => setShowFocus(false)}
            >
              <FocusArrow />
              <p className={styles.focusText}>
                {translate('Click here to select your delivery location')}
              </p>
            </div>
          </>
        ) : null}
        <div
          tabIndex={0}
          onKeyDown={() => setLocationDropdownOpen(true)}
          role="button"
          onClick={() => setLocationDropdownOpen(true)}
          ref={anchorRef}
          className={styles.toggleDropdown}
        >
          <LocationIcon className={styles.locationIcon} />
          <p className={styles.cityName}>
            {stateName && cityName
              ? `${decodeURIString(stateName)}, ${decodeURIString(cityName)}`
              : ''}
          </p>
        </div>
      </div>
      <DesktopDialog
        open={locationDropdownOpen}
        setOpen={toggleVisibility}
        anchorRef={anchorRef}
        className={styles.dropdownContainer}
      >
        <IconButton
          data-test="component-styled-dialog-close"
          className={styles.closeBtn}
          aria-label="Close"
          onClick={toggleVisibility}
        >
          <ClearIcon />
        </IconButton>
        <DialogContent
          onClose={toggleVisibility}
          setLoaded={setLoaded}
          stateAndCities={stateAndCities}
          cityId={cityId}
          stateId={stateId}
          stateName={stateName}
          cityName={cityName}
          landmark={landmark}
          landmarkId={landmarkId}
        />
      </DesktopDialog>
    </div>
  );
}
