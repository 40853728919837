/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
import classnames from 'classnames';
import Slider from 'react-slick';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { AuthUserContext } from '@/contexts/authUser';
import { GqlContext } from '@/contexts/gql';
import { clickBanner } from '@/utils/analytics';

const extractBannerImages = (bannerArray, src, userLang) => {
  const output = [];
  bannerArray
    .reduce(
      (available, data) => (Object.keys(data).includes(src)
        ? [
          ...available,
          {
            redirectTo: data.redirectType,
            redirectValue: data.redirectValue,
            imagesArray: data[`${src}`],
          },
        ]
        : available),
      [],
    )
    .forEach(({ redirectTo, redirectValue, imagesArray }) => {
      const image = imagesArray.reduce((result, imageObject) => {
        // eslint-disable-next-line no-param-reassign
        if (imageObject.language === userLang) result = imageObject.imageUrl;
        return result;
      }, '');
      if (image) {
        output.push({
          redirectTo,
          redirectValue,
          image,
        });
      }
    });
  return output;
};

export default function StyledCarousel({
  className,
  images = [],
  dots = true,
  arrows = false,
  infinite = true,
  useLargeImage = false,
  src = '',
  onChange = () => {},
  setImageBroken = () => {},
}) {
  const { site } = useContext(GqlContext);
  const { authUser, userLang } = useContext(AuthUserContext);
  // console.log('images array', images, src, userLang);
  const refinedImages =
    src && src !== 'bundle'
      ? extractBannerImages(images, src, userLang)
      : images;
  // console.log('refinedArray', refinedImages, src);
  const router = useRouter();
  const useSingleImage = refinedImages?.length === 1;
  const useBackupBanner = refinedImages?.length === 0 && src !== '';
  const containerClasses = classnames('carousel-container', className);

  const onClick = (image, redirectTo, redirectValue) => {
    if (!redirectTo?.length) return;
    console.log('redirectTo', redirectTo, 'redirectValue', redirectValue);
    const toCategoryListing = redirectTo?.includes('CATEGORY_LISTING');
    const toUrl = redirectTo?.includes('WEB_URL');
    const toProductListing = redirectTo?.includes('PRODUCT_LISTING');
    const toSeachTerm = redirectTo?.includes('SEARCH_TERM');
    if (toCategoryListing && redirectValue?.length) {
      // const redirectArray = redirectValue.trim().split(/\s*,\s*/);
      // console.log(redirectValue, redirectArray);
      router.push(
        `/category/${redirectValue.trim()}?cats=${redirectValue.trim()}&sort=newarrival`,
      );
    } else if (toUrl && redirectValue?.length) {
      window.open(redirectValue);
    } else if (toProductListing && redirectValue?.length) {
      router.push(
        `/search/${redirectValue.trim()}?search${redirectValue.trim()}&sort=newarrival&outOfStock=true&source=variant`,
      );
    } else if (toSeachTerm && redirectValue?.length) {
      router.push(
        `/search/${redirectValue.trim()}?search${redirectValue.trim()}&sort=newarrival&outOfStock=true&source=searchQuery`,
      );
    }
    if (authUser?.customerId) {
      clickBanner(image, redirectTo, redirectValue, authUser?.customerId);
    }
  };

  const backupBanner = [
    {
      image: site?.isSK
        ? '/images/topBannerMobileSk.png'
        : '/images/topBannerLatest.png',
      redirectTo: 'CATEGORY_LISTING',
      redirectValue: 'Food Items',
    },
  ];

  return (
    <div className={containerClasses}>
      <Slider
        infinite={useSingleImage ? false : infinite}
        dots={useSingleImage ? false : dots}
        arrows={useSingleImage ? false : arrows}
        afterChange={onChange}
        adaptiveHeight={false}
        centerMode={false}
        autoplay={useSingleImage ? false : infinite}
        autoplaySpeed={useSingleImage ? null : 4000}
      >
        {useSingleImage && images?.length ? (
          <div
            className={
              src === 'desktopVersion'
                ? 'desktop-container'
                : 'mobile-container'
            }
            key={refinedImages?.[0]?.redirectTo}
          >
            <img
              className={
                useLargeImage ? 'carousel-image-large' : 'carousel-image'
              }
              src={refinedImages?.[0]?.image}
              alt={refinedImages?.[0]?.redirectTo}
              onClick={() => onClick(
                backupBanner?.[0]?.image,
                refinedImages?.[0]?.redirectTo,
                refinedImages?.[0]?.redirectValue,
              )}
              onErrorCapture={() => {
                console.log('image broken single', refinedImages?.[0]?.image);
                setImageBroken((oldArray) => [
                  ...oldArray,
                  { image: refinedImages?.[0]?.image },
                ]);
              }}
              role="presentation"
            />
          </div>
        ) : useBackupBanner ? (
          <div
            className={
              src === 'desktopVersion'
                ? 'desktop-container'
                : 'mobile-container'
            }
            key={backupBanner?.[0]?.redirectTo}
          >
            <img
              className={
                useLargeImage ? 'carousel-image-large' : 'carousel-image'
              }
              src={
                src === 'desktopVersion' || src === 'mobileVersion'
                  ? backupBanner?.[0]?.image
                  : '/images/RegisterBG.png'
              }
              alt={backupBanner?.[0]?.redirectTo}
              onClick={() => onClick(
                backupBanner?.[0]?.image,
                backupBanner?.[0]?.redirectTo,
                backupBanner?.[0]?.redirectValue,
              )}
              role="presentation"
            />
          </div>
        ) : (
          refinedImages.map(({ image, redirectTo, redirectValue }, index) => (
            <div
              className={
                src === 'desktopVersion'
                  ? 'desktop-container'
                  : 'mobile-container'
              }
              key={`${redirectTo}_${index}`}
            >
              <img
                className={
                  useLargeImage ? 'carousel-image-large' : 'carousel-image'
                }
                src={image}
                alt={redirectTo}
                onClick={() => onClick(image, redirectTo, redirectValue)}
                role="presentation"
                onErrorCapture={() => {
                  console.log('image broken map', image);
                  setImageBroken((oldArray) => [...oldArray, { image }]);
                }}
              />
            </div>
          ))
        )}
      </Slider>
    </div>
  );
}
