export const LockedIcon = ({ className, size = 9 }) => (
  <svg
    className={className}
    width={`${size}`}
    height={`${(10 / 9) * size}`}
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71655 3.5V2.5C6.71655 1.1 5.61655 0 4.21655 0C2.81655 0 1.71655 1.1 1.71655 2.5V3.5C0.866553 3.5 0.216553 4.15 0.216553 5V8.5C0.216553 9.35 0.866553 10 1.71655 10H6.71655C7.56655 10 8.21655 9.35 8.21655 8.5V5C8.21655 4.15 7.56655 3.5 6.71655 3.5ZM2.71655 2.5C2.71655 1.65 3.36655 1 4.21655 1C5.06655 1 5.71655 1.65 5.71655 2.5V3.5H2.71655V2.5ZM4.76655 6.75L4.71655 6.8V7.5C4.71655 7.8 4.51655 8 4.21655 8C3.91655 8 3.71655 7.8 3.71655 7.5V6.8C3.41655 6.5 3.36655 6.05 3.66655 5.75C3.96655 5.45 4.41655 5.4 4.71655 5.7C5.01655 5.95 5.06655 6.45 4.76655 6.75Z"
      fill="#E4222E"
    />
  </svg>
);

export const UnlockedIcon = ({ className, size = 9 }) => (
  <svg
    className={className}
    width={`${size}`}
    height={`${(10 / 9) * size}`}
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71655 3.5H2.71655V2.5C2.71655 2.1 2.86655 1.75 3.16655 1.45C3.76655 0.85 4.71655 0.85 5.26655 1.45C5.46655 1.65 5.56655 1.9 5.66655 2.15C5.71655 2.4 6.01655 2.55 6.26655 2.5C6.51655 2.45 6.71655 2.15 6.61655 1.9C6.51655 1.45 6.26655 1.05 5.96655 0.75C5.51655 0.25 4.86655 0 4.21655 0C2.81655 0 1.71655 1.1 1.71655 2.5V3.5C0.866553 3.5 0.216553 4.15 0.216553 5V8.5C0.216553 9.35 0.866553 10 1.71655 10H6.71655C7.56655 10 8.21655 9.35 8.21655 8.5V5C8.21655 4.15 7.56655 3.5 6.71655 3.5ZM4.71655 7.5C4.71655 7.8 4.51655 8 4.21655 8C3.91655 8 3.71655 7.8 3.71655 7.5V6C3.71655 5.7 3.91655 5.5 4.21655 5.5C4.51655 5.5 4.71655 5.7 4.71655 6V7.5Z"
      fill="#00954B"
    />
  </svg>
);

export const WholesaleActiveIcon = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12C9.31368 12 12 9.31368 12 6C12 2.68629 9.31368 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31368 2.68629 12 6 12ZM8.1213 3.87868C8.35566 3.64436 8.73552 3.64436 8.96988 3.87868C9.20418 4.11299 9.20418 4.4929 8.96988 4.72721L5.57796 8.11908L5.57574 8.1213C5.51514 8.1819 5.44482 8.22684 5.36976 8.25612C5.26344 8.29752 5.1477 8.30754 5.03688 8.28606C4.92342 8.2641 4.81506 8.2092 4.72721 8.1213L4.72493 8.11902L3.03017 6.42426C2.79585 6.18996 2.79585 5.81004 3.03017 5.57574C3.26448 5.34144 3.64438 5.34144 3.87869 5.57574L5.15148 6.84852L8.1213 3.87868Z"
      fill="#95D6B6"
    />
  </svg>
);
