import { useContext } from 'react';
import classnames from 'classnames';

// Material
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// Utils
import { useTranslation } from 'react-i18next';
import { formatAmount } from '@/utils/helper';
import { GqlContext } from '@/contexts/gql';

// Styles
import styles from './style.module.css';

export default function CartSummaryTable({
  isCheckout = false,
  totalPrice,
  // discount,
  grossTotal,
  shippingAddress,
  isMiniCart,
  appliedCoupon,
  availableLimit,
  totalLimit,
  bundleSavings,
  subTotal = 0,
  orderDeliveryFee = 0,
  orderProcessingFee = 0,
  orderHandlingFee = 0,
}) {
  const { t: translate } = useTranslation();
  console.log(orderDeliveryFee, orderProcessingFee, orderHandlingFee);
  const { country } = useContext(GqlContext);
  const couponName = classnames(styles.summaryHead, styles.couponName);
  const couponDiscount = classnames(styles.summaryValue, styles.couponDiscount);
  console.log({ subTotal }, { totalPrice });
  const appliedCouponDiscount = (appliedCoupon && appliedCoupon.discount) || 0;
  const orderSubTotal = subTotal || totalPrice + appliedCouponDiscount + bundleSavings;
  const wholesaleSavings = grossTotal - orderSubTotal;

  return (
    <Table
      className={!isMiniCart ? styles.summaryTable : null}
      aria-label="order summary table"
    >
      <TableBody>
        {!isMiniCart && (
          <TableRow className={styles.summaryRow}>
            <TableCell
              className={styles.summaryHead}
              component="th"
              scope="row"
            >
              Subtotal
            </TableCell>
            <TableCell className={styles.summaryValue} align="right">
              {/* {`${country?.currencySymbol} ${formatAmount(
                totalPrice + discount,
                true,
              )}`} */}
              {`${country?.currencySymbol} ${formatAmount(grossTotal, true)}`}
            </TableCell>
          </TableRow>
        )}
        {wholesaleSavings > 0 && !isMiniCart && (
          <TableRow>
            <TableCell
              className={styles.summaryHead}
              component="th"
              scope="row"
            >
              Wholesale Savings
            </TableCell>
            <TableCell className={styles.summaryValue} align="right">
              {/* {`${country?.currencySymbol} ${formatAmount(
                discount - appliedCouponDiscount,
                true,
              )}`} */}
              {`- ${country?.currencySymbol} ${formatAmount(
                wholesaleSavings,
                true,
              )}`}
            </TableCell>
          </TableRow>
        )}

        {bundleSavings > 0 && !isMiniCart && (
          <TableRow>
            <TableCell
              className={styles.summaryHead}
              component="th"
              scope="row"
            >
              Bundle Savings
            </TableCell>
            <TableCell className={styles.summaryValue} align="right">
              {`- ${country?.currencySymbol} ${formatAmount(
                bundleSavings,
                true,
              )}`}
            </TableCell>
          </TableRow>
        )}

        {!isMiniCart && isCheckout && (
          <TableRow>
            <TableCell
              className={styles.summaryHead}
              component="th"
              scope="row"
            >
              Delivery Fee
            </TableCell>
            <TableCell className={styles.summaryValue} align="right">
              {country?.currencySymbol}
              {' '}
              {formatAmount(orderDeliveryFee, true)}
            </TableCell>
          </TableRow>
        )}

        {!isMiniCart && isCheckout && (
          <TableRow>
            <TableCell
              className={styles.summaryHead}
              component="th"
              scope="row"
            >
              Processing and Handling Fees
            </TableCell>
            <TableCell className={styles.summaryValue} align="right">
              {country?.currencySymbol}
              {formatAmount(orderProcessingFee + orderHandlingFee, true)}
            </TableCell>
          </TableRow>
        )}

        {appliedCouponDiscount > 0 && (
          <TableRow>
            <TableCell className={couponName} component="th" scope="row">
              {appliedCoupon.couponName}
            </TableCell>
            <TableCell className={couponDiscount} align="right">
              {`- ${country?.currencySymbol} ${formatAmount(
                appliedCouponDiscount,
                true,
              )}`}
            </TableCell>
          </TableRow>
        )}

        {shippingAddress && !isMiniCart && (
          <TableRow>
            <TableCell
              className={styles.summaryHead}
              component="th"
              scope="row"
            >
              Shipping Address
            </TableCell>
            <TableCell className={styles.summaryValue} align="right">
              {shippingAddress}
            </TableCell>
          </TableRow>
        )}
        <TableRow className={styles.summaryTotal}>
          <TableCell
            className={styles.summaryTotalValue}
            component="th"
            scope="row"
          >
            {translate('Order Total')}
            <span>(inclusive all taxes)</span>
          </TableCell>
          <TableCell className={styles.summaryValue} align="right">
            {`${country?.currencySymbol} ${formatAmount(
              isCheckout
                ? totalPrice
                    + orderDeliveryFee
                    + orderProcessingFee
                    + orderHandlingFee
                : totalPrice,
              true,
            )}`}
          </TableCell>
        </TableRow>
        {availableLimit > 0 && availableLimit === totalLimit ? (
          <TableRow className={styles.summaryTotal}>
            <TableCell
              className={styles.summaryTotalValue}
              component="th"
              scope="row"
            >
              {translate('Buy Now, Pay Later')}
            </TableCell>
            <TableCell className={styles.summaryValue} align="right">
              <a
                href={
                  country?.bnplTermsURL !== null
                    ? country?.bnplTermsURL
                    : 'https://alerzoshop.com/pay-later-terms'
                }
                target="_blank"
                rel="noreferrer"
              >
                T&C
              </a>
            </TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    </Table>
  );
}
