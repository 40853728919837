// Core Packages
import { useEffect, useState } from 'react';

// Custom Components
import StyledButton from '@/components/Button';
// import StyledSelect from '@/components/Select';
import SearchableDropdown from '@/components/SearchableDropdown';

// Utils
import { getCookie } from '@/utils/helper';
import { useTranslation } from 'react-i18next';
import LoaderDialog from '@/components/LoaderDialog';
import LandmarkMap from '../LandmarkMap';

// Styles
import styles from './style.module.css';

const CitySelectionAlerzo = ({
  // onClose,
  stateAndCities,
  // noTitle,
  // Form data
  handleSubmit,
  setLandmark,
  landmarks,
  setLandmarks,
  setCity,
  setState,
  form,
  showMap,
  loading,
}) => {
  const { t: translate } = useTranslation();
  const [mapCenter, setMapCenter] = useState(null);
  const getKey = (key) => (option) => option[key];

  const stateCityMap = stateAndCities?.reduce(
    (map, state) => ({ ...map, [state.stateId]: state.Cities }),
    {},
  );
  const cities = stateCityMap?.[form.stateId] || [];

  useEffect(() => {
    if (form.cityId && form.stateId && stateAndCities.length > 0) {
      const matchingState = stateAndCities.find(
        (s) => s.stateId === form.stateId,
      );
      const matchingCity = matchingState.Cities.find(
        (c) => c.cityId === form.cityId,
      );
      setLandmarks(matchingCity.Landmarks);
    }
  }, [form.cityId, form.stateId, stateAndCities]);

  useEffect(async () => {
    const oldLandmark = await getCookie('landmarkId');
    const oldLandmarkName = await getCookie('landmark');
    const oldWarehouse = await getCookie('warehouse');
    setLandmark({
      landmarkId: oldLandmark,
      landmarkName: oldLandmarkName,
      warehouseId: oldWarehouse,
    });
  }, []);

  useEffect(() => {
    if (form.cityId && !form.landmarkId) {
      const matchingCity = cities.find((c) => c.cityId === form.cityId);

      if (!matchingCity) return;
      const { cityLat, cityLong } = matchingCity;

      setMapCenter({
        lat: cityLat || 0,
        lng: cityLong || 0,
      });
    }

    if (landmarks?.length && !!form.landmarkId && !!form.cityId) {
      const matchingLandmark = landmarks.find(
        (l) => l.landmarkId === form.landmarkId,
      );
      if (matchingLandmark) {
        setMapCenter({
          lat: matchingLandmark.lat || 0,
          lng: matchingLandmark.long || 0,
        });
      }
    }
  }, [form.cityId, form.landmarkId, landmarks]);

  return (
    <>
      {/* {!noTitle ? (
        <h4 className={styles.title}>{translate("Delivery Location")}</h4>
      ) : null} */}

      <form
        onSubmit={handleSubmit}
        style={loading ? { position: 'relative' } : {}}
      >
        <LoaderDialog loading={loading} />

        <div className={styles.rowForFlex} style={{ marginBottom: '10px' }}>
          <div className={styles.flexItem}>
            <SearchableDropdown
              values={stateAndCities}
              getLabel={getKey('stateName')}
              getValue={getKey('stateId')}
              required
              label={translate('State')}
              onChange={setState()}
              value={form.stateId}
              placeholder={translate('Select State')}
            />
            {/* <StyledSelect
              label={translate('State')}
              className={styles.input}
              placeholder={translate('Select State')}
              value={form.stateId}
              options={stateAndCities}
              getLabel={getKey('stateName')}
              getValue={getKey('stateId')}
              onChange={setState()}
              required
              searchable
              searchPlaceholder={translate('Search')}
            /> */}
          </div>

          <div className={styles.flexItem}>
            <SearchableDropdown
              required
              label={translate('City')}
              className={styles.input}
              placeholder={translate('Select City')}
              value={form.cityId}
              values={cities}
              getLabel={getKey('cityName')}
              getValue={getKey('cityId')}
              onChange={setCity()}
            />
          </div>
        </div>
        <SearchableDropdown
          label={translate('Landmark')}
          className={styles.input}
          placeholder={translate('Select Landmark')}
          value={form.landmarkId}
          values={landmarks}
          getLabel={getKey('landmarkName')}
          getValue={getKey('landmarkId')}
          onChange={setLandmark}
          searchable
          searchPlaceholder={translate('Search Landmark Here...')}
        />

        <StyledButton
          type="submit"
          className={styles.actionButton}
          disabled={!form.cityId || !form.stateId || !form.landmarkId}
        >
          {translate('Confirm Location')}
        </StyledButton>

        {form?.cityName && showMap ? (
          <LandmarkMap
            landmarks={landmarks}
            onClickLandmark={setLandmark}
            landmarkId={form.landmarkId}
            mapCenter={mapCenter}
          />
        ) : null}
      </form>
    </>
  );
};

export default CitySelectionAlerzo;
