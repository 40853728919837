import { TrendingArrowIcon } from '@/assets/icons/shared';
import Link from 'next/link';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';
import styles from './style.module.css';

const TrendingItem = ({
  item, onClick, className = {}, source = '',
}) => {
  const chipClasses = classNames(className, styles.chip);
  return (
    <Link href={`/search/${item}?search=${item}&source=${source}`}>
      <Chip
        className={chipClasses}
        key={item}
        label={item}
        onClick={onClick}
        icon={<TrendingArrowIcon className={styles.icon} />}
      />
    </Link>
  );
};

export default TrendingItem;
